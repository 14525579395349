import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate from react-router-dom
import Sidebar from '../admin_components/Sidebar';
import Header from '../admin_components/Header';
import { Card, Row, Col, Button } from 'react-bootstrap';  // Import Button from react-bootstrap
import { supabase } from '../supabaseClient';
import 'bootstrap/dist/css/bootstrap.min.css';

const EmployeeReturned = () => {
    const [todaysRequests, setTodaysRequests] = useState([]);
    const navigate = useNavigate();  // Initialize navigate function

    // Function to format date to compare with today's date
    const formatDate = (date) => {
        const d = new Date(date);
        return d.toISOString().split('T')[0]; // Returns date in YYYY-MM-DD format
    };

    // Fetch the activity logs where activity_type is 'Pass Slip Request' and created_at is today's date
    const fetchTodaysRequests = async () => {
        const today = formatDate(new Date());

        const { data, error } = await supabase
            .from('activity_logs')
            .select('activity_type, description, created_at')
            .eq('activity_type', 'Returned') // Filter by 'Pass Slip Request'
            .filter('created_at', 'gte', new Date().toISOString().split('T')[0]) // Filter by today's date (start of the day)
            .filter('created_at', 'lt', new Date().toISOString().split('T')[0] + "T23:59:59") // Ensure it's only today's logs (before end of day)


        if (error) {
            console.error('Error fetching today\'s requests:', error);
        } else {
            setTodaysRequests(data); // Set the fetched data to state
        }
    };

    useEffect(() => {
        fetchTodaysRequests(); // Fetch today's requests when the component is mounted
    }, []);

    // Back button handler
    const handleBackClick = () => {
        navigate('/Monitoring');  // Navigate to the /Monitoring route
    };

    return (
        <div
            className="dashboard-container"
            style={{
                display: 'flex',
                backgroundColor: '#f4f4f4',
                color: '#000',
                minHeight: '100vh',
            }}
        >
            <Sidebar />

            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh', // Make sure it takes up full height
                }}
            >
                <Header />

                <main
                    className="main-content"
                    style={{
                        backgroundColor: '#ffffff',
                        padding: '20px',
                        borderRadius: '12px',
                        margin: '20px',
                        color: '#000',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        overflowY: 'auto', // Enable vertical scrolling for activity logs only
                        flex: 1, // Allow the main section to take up the remaining space
                        maxHeight: 'calc(100vh - 80px)', // Adjust the height to account for header
                    }}
                >
                    <Button variant="secondary" onClick={handleBackClick} style={{ marginBottom: '20px' }}>
                        Back
                    </Button>

                    <h1>Employee Returned Today</h1>

                    <Row>
                        {todaysRequests.length === 0 ? (
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Card.Text>No record found for today.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ) : (
                            todaysRequests.map((request, index) => (
                                <Col key={index} md={12}>
                                    <Card style={{ marginBottom: '10px' }}>
                                        <Card.Body>
                                         
                                            <Card.Text>
                                                <strong>Description:</strong> {request.description}
                                            </Card.Text>
                                            <Card.Text>
                                                <strong>Date:</strong> {new Date(request.created_at).toLocaleString()}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        )}
                    </Row>
                </main>
            </div>
        </div>
    );
};

export default EmployeeReturned;
