import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, Card, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../OIC_components_admin/Sidebar';
import Header from '../OIC_components_admin/Header';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com'; // Import emailjs

const OICSlipReqAdmin = () => {
  const [selectedSlip, setSelectedSlip] = useState(null);
  const [slips, setSlips] = useState([]);
  const [filteredSlips, setFilteredSlips] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || user.role !== 'Acting Administrative') {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchSlips = async () => {
        const { data, error } = await supabase.from('pass_slip_req').select('*');
        if (error) {
          console.error('Error fetching slips:', error);
        } else {
          const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
          const filteredSlips = data.filter(slip => {
            // Filter slips where the date matches today's date and status is not 'Rejected' or 'Approved by the Head Office and Admin'
            const slipDate = new Date(slip.date).toISOString().split('T')[0]; // Format the slip date
            return slipDate === today && slip.status !== 'Rejected' && slip.status !== 'Approved by the Head Office and Admin';
          });
          const sortedSlips = filteredSlips.sort((a, b) => new Date(b.date) - new Date(a.date));
          setSlips(sortedSlips);
          setFilteredSlips(sortedSlips);
        }
      };
      
    fetchSlips();
  }, []);

  useEffect(() => {
    const results = slips.filter(slip => {
      return (
        slip.purpose.toLowerCase().includes(searchTerm.toLowerCase()) ||
        slip.employee_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFilteredSlips(results);
  }, [searchTerm, slips]);

  const sendApprovalEmail = async (email) => {
    try {
      await emailjs.send('service_crla1iv', 'template_w1ck8re', {
        to_email: email,
        reply_to: 'no-reply@example.com', 
        message: 'Your request has been approved by the Campus Director. You can now access your QR code.',
      }, 'LtbIUA7B49TOggb24');
      console.log('Email sent successfully');
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const handleApprove = async (id) => {
    console.log('Approving slip with ID:', id); // Log the slip ID
    const { data, error } = await supabase
      .from('pass_slip_req')
      .update({ status: 'Approved by the Head Office and Admin' })
      .eq('id', id);
  
    if (error) {
      console.error('Error approving slip:', error);
    } else {
      console.log('Slip approved successfully:', data);
      setSlips((prevSlips) =>
        prevSlips.map((slip) =>
          slip.id === id ? { ...slip, status: 'Approved by the Head Office and Admin' } : slip
        )
      );
  
      // Send email notification
      if (selectedSlip && selectedSlip.employee_email) {
        console.log('Sending approval email to:', selectedSlip.employee_email); // Log the email
        await sendApprovalEmail(selectedSlip.employee_email);
      }
      setShowConfirmationModal(false);
    }
  };
  

  const handleShowDetailModal = (slip) => {
    setSelectedSlip(slip);
    setShowDetailModal(true);
  };

  const handleCloseDetailModal = () => setShowDetailModal(false);

  const handleShowConfirmationModal = (slip) => {
    setSelectedSlip(slip);
    setShowConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  return (
    <div
      className="dashboard-container"
      style={{
        display: 'flex',
        backgroundColor: '#f4f4f4',
        color: '#000',
        minHeight: '100vh',
      }}
    >
      <Sidebar />
      <div style={{ flex: 1 }}>
        <Header />
        <main
          className="main-content"
          style={{
            backgroundColor: '#ffffff',
            padding: '20px',
            borderRadius: '12px',
            margin: '20px',
            color: '#000',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            overflowY: 'auto',
            height: 'calc(100vh - 80px)',
          }}
        >
          <h1>Pass Slip Requests</h1>

          <Form.Group controlId="search">
            <Form.Control
              type="text"
              placeholder="Search by Purpose or Employee Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ marginBottom: '20px' }}
            />
          </Form.Group>

          <Table
            className="table-responsive"
            striped
            bordered
            hover
            variant={'light'}
            style={{
              backgroundColor: '#fff',
              color: '#333',
            }}
          >
            <thead>
              <tr>
                <th>Purpose</th>
                <th>Department</th>
                <th>Destination</th>
                <th>Employee Name</th>
                <th>Date of Request</th> {/* New column for Date */}
                <th>Time of Request</th> {/* New column for Time */}
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredSlips.map((slip) => {
                const requestDate = new Date(slip.date);
                const requestDateFormatted = requestDate.toLocaleDateString(); // Get the formatted date
                const requestTimeFormatted = requestDate.toLocaleTimeString(); // Get the formatted time
                return (
                  <tr key={slip.id}>
                    <td>{slip.purpose}</td>
                    <td>{slip.department}</td>
                    <td>{slip.destination}</td>
                    <td>{slip.employee_name}</td>
                    <td>{requestDateFormatted}</td> {/* Date column */}
                    <td>{requestTimeFormatted}</td> {/* Time column */}
                    <td>{slip.status}</td>
                    <td>
                      <Button
                        variant="info"
                        size="sm"
                        className="me-2"
                        onClick={() => handleShowDetailModal(slip)}
                      >
                        View Details
                      </Button>
                      {slip.status === 'Approved by the Head Office' && (
                        <Button
                          variant="success"
                          onClick={() => handleShowConfirmationModal(slip)}
                        >
                          Approve
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

         {/* Detail Modal */}
          <Modal
            show={showDetailModal}
            onHide={handleCloseDetailModal}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Pass Slip Details</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: '#fff' }}>
              {selectedSlip ? (
                <div>
                  {/* Date Requested */}
                  <Card
                    style={{
                      marginBottom: '15px',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                      backgroundColor: '#f8f9fa',
                    }}
                  >
                    <Card.Body>
                      <Card.Title>Date Requested</Card.Title>
                      <Card.Text>{new Date(selectedSlip.date).toLocaleDateString()}</Card.Text>
                    </Card.Body>
                  </Card>

                  {/* Destination */}
                  <Card
                    style={{
                      marginBottom: '15px',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                      backgroundColor: '#f8f9fa',
                    }}
                  >
                    <Card.Body>
                      <Card.Title>Where to go / Destination</Card.Title>
                      <Card.Text>{selectedSlip.destination}</Card.Text>
                    </Card.Body>
                  </Card>

                  {/* Purpose */}
                  <Card
                    style={{
                      marginBottom: '15px',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                      backgroundColor: '#f8f9fa',
                    }}
                  >
                    <Card.Body>
                      <Card.Title>Purpose</Card.Title>
                      <Card.Text>{selectedSlip.purpose}</Card.Text>
                    </Card.Body>
                  </Card>

                  {/* Expected Duration */}
                  <Card
                    style={{
                      marginBottom: '15px',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                      backgroundColor: '#f8f9fa',
                    }}
                  >
                    <Card.Body>
                      <Card.Title style={{ color: '#000' }}>Expected Duration</Card.Title>
                      <Card.Text style={{ fontSize: '16px', color: '#000' }}>
                        {(() => {
                          if (!selectedSlip.expected_duration || selectedSlip.expected_duration <= 0) {
                            return 'N/A';
                          }
                          const hours = Math.floor(selectedSlip.expected_duration / 60);
                          const minutes = selectedSlip.expected_duration % 60;
                          return `${hours} hr${hours !== 1 ? 's' : ''} and ${minutes} min${minutes !== 1 ? 's' : ''}`;
                        })()}
                      </Card.Text>
                    </Card.Body>
                  </Card>

                   {/* Employee Name */}
                   <Card
                    style={{
                      marginBottom: '15px',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                      backgroundColor: '#f9f9f9',
                    }}
                  >
                    <Card.Body>
                      <Card.Title style={{ color: '#000' }}>Employee Name</Card.Title>
                      <Card.Text style={{ fontSize: '16px', color: '#000' }}>
                        {selectedSlip.employee_name}
                      </Card.Text>
                    </Card.Body>
                  </Card>

                  {/* Employee Email */}
                  <Card
                    style={{
                      marginBottom: '15px',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                      backgroundColor: '#f8f9fa',
                    }}
                  >
                    <Card.Body>
                      <Card.Title>Employee Email</Card.Title>
                      <Card.Text>{selectedSlip.employee_email}</Card.Text>
                    </Card.Body>
                  </Card>

                  {/* Department */}
                  <Card
                    style={{
                      marginBottom: '15px',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                      backgroundColor: '#f8f9fa',
                    }}
                  >
                    <Card.Body>
                      <Card.Title>Department</Card.Title>
                      <Card.Text>{selectedSlip.department}</Card.Text>
                    </Card.Body>
                  </Card>

                  {/* Phone Number */}
                  <Card
                    style={{
                      marginBottom: '15px',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                      backgroundColor: '#f8f9fa',
                    }}
                  >
                    <Card.Body>
                      <Card.Title>Phone Number</Card.Title>
                      <Card.Text>{selectedSlip.phone_number}</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              ) : (
                <p>No details available.</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDetailModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Confirmation Modal */}
          <Modal
            show={showConfirmationModal}
            onHide={handleCloseConfirmationModal}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Approval</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to approve this request?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseConfirmationModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => handleApprove(selectedSlip.id)}>
                Approve
              </Button>
            </Modal.Footer>
          </Modal>
        </main>
      </div>
    </div>
  );
};

export default OICSlipReqAdmin;
