import React, { useState, useEffect } from 'react';
import Sidebar from '../admin_components/Sidebar';
import Header from '../admin_components/Header';
import { supabase } from '../supabaseClient';
import { Table, Button, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ManageOIC = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [oicList, setOICList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedOIC, setSelectedOIC] = useState(null);
    const [newOIC, setNewOIC] = useState({
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        department: '',
        phone_number: '',
        role: '', // New acting_role field
    });

    const navigate = useNavigate();

    const fetchAndSetOICList = async () => {
        try {
            const { data, error } = await supabase.from('officer_in_charge').select('*').order('created_at', { ascending: false });
            if (error) {
                console.error('Error fetching OIC list:', error);
                return;
            }
            if (Array.isArray(data)) {
                setOICList(data);
            } else {
                console.error('Unexpected data format:', data);
            }
        } catch (error) {
            console.error('Error fetching OIC data:', error);
        }
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user || user.role !== 'admin') {
            navigate('/');
        }
        fetchAndSetOICList();

        const intervalId = setInterval(() => {
            fetchAndSetOICList();
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    const addOIC = async () => {
        if (!/^\d+$/.test(newOIC.phone_number)) {
            alert('Phone number must contain only numbers.');
            return;
        }
    
        try {
            // Get admin's name from the local storage
            const user = JSON.parse(localStorage.getItem('user'));
            const { data: adminData, error: adminError } = await supabase
                .from('admin')
                .select('firstname, lastname')
                .eq('email', user.email)
                .single();
    
            if (adminError) {
                console.error('Error fetching admin data:', adminError);
                alert('Error fetching admin data: ' + adminError.message);
                return;
            }
    
            const adminFullName = `${adminData.firstname} ${adminData.lastname}`;
    
            // Insert the new OIC into the 'officer_in_charge' table
            const { data, error } = await supabase.from('officer_in_charge').insert([newOIC]);
    
            if (error) {
                console.error('Error adding OIC:', error);
                alert('Error adding OIC: ' + error.message);
                return;
            }
    
            if (data) {
                // Fetch and update the OIC list
                fetchAndSetOICList();
    
                // Log the activity in the 'activity_logs' table
                await logActivity(adminFullName);
    
                // Show success alert or message (optional)
                alert('New Office-in-Charge added successfully.');
            }
        } catch (error) {
            console.error('Error in addOIC:', error);
            alert('Error in addOIC: ' + error.message);
        } finally {
            // Close modal and reset the OIC form fields
            setShowModal(false);
            setNewOIC({
                email: '',
                password: '',
                firstname: '',
                lastname: '',
                department: '',
                phone_number: '',
                role: '', // Reset acting_role field
            });
        }
    };
    
    // Function to log the activity in the activity_logs table
    const logActivity = async (adminName) => {
        const { data, error } = await supabase
            .from('activity_logs')
            .insert([
                {
                    activity_type: 'Add OIC',
                    description: `Admin (${adminName}) Added a new Office-in-Charge`,
                },
            ]);
    
        if (error) {
            console.log('Error logging activity:', error);
            alert('Failed to log activity: ' + error.message);  // Show a user-friendly error message
        } else {
            console.log('Activity logged successfully:', data);
        }
    };
    
    const deleteOIC = async () => {
        if (!selectedOIC) return;

        try {
            const { error } = await supabase.from('officer_in_charge').delete().eq('id', selectedOIC.id);
            if (error) {
                console.error('Error deleting OIC:', error);
                return;
            }
            await fetchAndSetOICList();
        } catch (error) {
            console.error('Error deleting OIC:', error);
        } finally {
            setShowDeleteModal(false);
            setSelectedOIC(null);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewOIC(prev => ({ ...prev, [name]: value }));
    };

    const handlePhoneInput = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/\D/g, '');
        setNewOIC(prev => ({ ...prev, phone_number: numericValue }));
    };

    const handleViewInfo = (oic) => {
        navigate(`/ViewOICInfo?id=${oic.id}`);
    };

    return (
        <div className="dashboard-container" style={{ display: 'flex', backgroundColor: isDarkMode ? '#333' : 'lightgrey', color: isDarkMode ? '#f0f0f0' : '#000', minHeight: '100vh' }}>
            <Sidebar />
            <div style={{ flex: 1 }}>
                <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />
                <main className="main-content" style={{ backgroundColor: isDarkMode ? '#444' : 'white', padding: '20px', borderRadius: '8px', margin: '20px', color: isDarkMode ? '#f0f0f0' : '#000' }}>
                    <h1>Manage Officer-in-Charge (OIC)</h1>
                    <div>
                        <Button variant="primary" onClick={() => setShowModal(true)}>
                            Add New Officer-in-Charge
                        </Button>
                    </div>
                    <div className="table-responsive">
                        <Table striped bordered hover variant={isDarkMode ? 'dark' : 'light'}>
                            <thead>
                                <tr className="text-center">
                                    <th>Email</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Office</th>
                                    <th>Phone Number</th>
                                    <th>Acting Role</th> {/* Added Acting Role column */}
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {oicList.length === 0 ? (
                                    <tr>
                                        <td colSpan="7" className="text-center">No OIC found</td> {/* Adjusted colspan */}
                                    </tr>
                                ) : (
                                    oicList.map((oic) => (
                                        <tr key={oic.id} className="text-center">
                                            <td>{oic.email}</td>
                                            <td>{oic.firstname}</td>
                                            <td>{oic.lastname}</td>
                                            <td>{oic.department}</td>
                                            <td>{oic.phone_number}</td>
                                            <td>{oic.role || 'N/A'}</td> {/* Display Acting Role */}
                                            <td> 
                                                <Button variant="success" size="sm" onClick={() => handleViewInfo(oic)}>View Info</Button>
                                                <Button variant="danger" size="sm" onClick={() => { setSelectedOIC(oic); setShowDeleteModal(true); }}>Delete</Button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </div>

                    {/* Modal for adding new OIC */}
                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add New Officer-in-Charge</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={newOIC.email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        value={newOIC.password}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="firstname"
                                        value={newOIC.firstname}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="lastname"
                                        value={newOIC.lastname}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Acting Role</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="role"
                                        value={newOIC.role}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">Select Acting Role</option>
                                        <option value="Acting Department Head/Head Office">Acting Department Head/Head Office</option>
                                        <option value="Acting Administrative">Acting Administrative</option>
                                    </Form.Control>
                                </Form.Group>

                                {newOIC.role === 'Acting Department Head/Head Office' && (
                                    <Form.Group>
                                        <Form.Label>Office</Form.Label>
                                        <Form.Select
                                            name="department"
                                            value={newOIC.department}
                                            onChange={handleInputChange}
                                            required
                                        >

                                            <option value="">Select Department/Office</option> {/* Default empty option */}
                                            <option value="Accounting Office">Accounting Office</option>
                                            <option value="Administrative and Finance Services">Administrative and Finance Services</option>
                                            <option value="Budget Office">Budget Office</option>
                                            <option value="Business and Management Department">Business and Management Department</option>
                                            <option value="Campus Clinic">Campus Clinic</option>
                                            <option value="Cashier and Disbursing Office">Cashier and Disbursing Office</option>
                                            <option value="Computer Studies Department">Computer Studies Department</option>
                                            <option value="Culture and the Arts">Culture and the Arts</option>
                                            <option value="Engineering Department">Engineering Department</option>
                                            <option value="Gender and Development Office">Gender and Development Office</option>
                                            <option value="Human Resource Management Office">Human Resource Management Office</option>
                                            <option value="Institutional Planning and Development Office">Institutional Planning and Development Office</option>
                                            <option value="Library">Library</option>
                                            <option value="Maintenance and Engineering">Maintenance and Engineering</option>
                                            <option value="NSTP Office">NSTP Office</option>
                                            <option value="Office of the Campus Director">Office of the Campus Director</option>
                                            <option value="Property Inspector">Property Inspector</option>
                                            <option value="Quality Assurance Accreditation Center">Quality Assurance Accreditation Center</option>
                                            <option value="Registrar Office">Registrar Office</option>
                                            <option value="Teacher Education Department">Teacher Education Department</option>
                                            <option value="Technology Department">Technology Department</option>
                                            <option value="Technology Department">Guidance Office</option>
                                            <option value="Technology Department">Income-Generating Projects </option>
                                            <option value="Technology Department">Student Affairs and Services Office</option>
                                        </Form.Select>
                                    </Form.Group>
                                )}

                                <Form.Group>
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone_number"
                                        value={newOIC.phone_number}
                                        onChange={handlePhoneInput}
                                        required
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                            <Button variant="primary" onClick={addOIC}>Save</Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Modal for confirming deletion */}
                    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Deletion</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete this Officer-in-Charge?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                            <Button variant="danger" onClick={deleteOIC}>Delete</Button>
                        </Modal.Footer>
                    </Modal>
                </main>
            </div>
        </div>
    );
};

export default ManageOIC;
