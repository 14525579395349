import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Alert, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import { supabase } from './supabaseClient';
import evsuLogo from './images/EVSU.png';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Modified handleSubmit function
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email === '' || password === '') {
      setError('Both fields are required');
      return;
    }

    try {
      console.log('Attempting login with email:', email);  // Debugging line
      // First check in the admin table
      let { data, error } = await supabase
        .from('admin')
        .select('id, email, password, role, firstname, lastname')
        .eq('email', email)
        .single();

      // If not found, check in the department_head table
      if (!data) {
        console.log('Not found in admin table, checking department_head...');
        ({ data, error } = await supabase
          .from('department_head')
          .select('id, email, password, role, department, firstname, lastname')
          .eq('email', email)
          .single());
      }

      // If not found in department_head, check in the officer_in_charge table
      if (!data) {
        console.log('Not found in department_head table, checking officer_in_charge...');
        ({ data, error } = await supabase
          .from('officer_in_charge')
          .select('id, email, password, role, department, firstname, lastname')
          .eq('email', email)
          .single());
      }

      // Check if the password matches
      if (error || !data || data.password !== password) {
        console.log('Error or invalid credentials:', error);
        setError('Invalid email or password');
        return;
      }

      console.log('Login successful, user data:', data);  // Debugging line

      // Store user data in localStorage
      const userData = {
        id: data.id,
        email: data.email,
        role: data.role,
      };
      localStorage.setItem('user', JSON.stringify(userData));

      // Create the full name from firstname and lastname
      const fullName = `${data.firstname} ${data.lastname}`;

      // Log the activity
      const activityDescription = `${data.role === 'admin' ? 'Admin' : data.role === 'department_head' ? 'Department Head' : data.role === 'Acting Department Head/Head Office' ? 'Acting Department Head' : 'Acting Admin'} ${fullName} logged in to the system`;
    
      const philippinesTime = new Date().toLocaleString("en-US", {
        timeZone: "Asia/Manila",  // Philippines timezone
      });
      const createdAt = new Date(philippinesTime).toISOString();  // Convert to ISO format
      
      const { data: logData, error: logError } = await supabase
        .from('activity_logs')
        .insert([
          {
            activity_type: 'login',
            description: activityDescription,
          },
        ])
        .select(); 
      
      if (logError) {
        console.error('Error logging activity:', logError);
      } else {
        console.log('Activity logged successfully:', logData);  
      }
      

      // Navigate based on the role
      if (data.role === 'department_head') {
        console.log('Navigating to DepartmentHD...');
        navigate('/DepartmentHD');
      } else if (data.role === 'admin') {
        console.log('Navigating to admin-dashboard...');
        navigate('/admin-dashboard');
      } else if (data.role === 'Acting Department Head/Head Office') {
        console.log('Navigating to OICDashboard...');
        navigate('/OICDashboard');  
      } else if (data.role === 'Acting Administrative') {
        console.log('Navigating to the homepage...');
        navigate('/OICDashboardAdmin');  
      }
    } catch (error) {
      console.log('Error during login:', error);
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="login-background">
      <Container className="d-flex justify-content-center align-items-center min-vh-100">
        <Row className="w-100">
          <Col xs={12} md={6} className="d-flex justify-content-center align-items-center mb-4 mb-md-0">
            <img src={evsuLogo} alt="EVSU Logo" className="img-fluid" style={{ maxWidth: '300px' }} />
          </Col>
          <Col xs={12} md={6}>
            <Card className="p-4 shadow-lg">
              <h2 className="text-center mb-4">Login</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>

                {error && <Alert variant="danger">{error}</Alert>}

                <Button variant="primary" type="submit" className="w-100">
                  Login
                </Button>
              </Form>

              <div className="mt-3 text-center">
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
