import React, { useState, useEffect } from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { supabase } from '../supabaseClient'; // Import Supabase client

const Sidebar = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true); // Manage authentication state
  const [userName, setUserName] = useState('');
  const [userFullName, setUserFullName] = useState(''); // To store full name
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) {
      console.warn('User not authenticated. Redirecting to login page.');
      setIsAuthenticated(false); // Update state
      navigate('/'); // Redirect to login
    } else {
      setUserName(user.name); // Assuming user object contains a name property
      fetchUserDetails(user.id); // Fetch the user's firstname and lastname
    }
  }, [navigate]);

  // Fetch the user's firstname and lastname from the office_in_charge table
  const fetchUserDetails = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('officer_in_charge') // Table name
        .select('firstname, lastname') // Fields to retrieve
        .eq('id', userId) // Matching the user id
        .single(); // Fetch a single record

      if (error) throw error;
      if (data) {
        const fullName = `${data.firstname} ${data.lastname}`;
        setUserFullName(fullName); // Store the full name
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  // Function to log the logout activity
  const logLogoutActivity = async () => {
    const activityDescription = `Acting Department/Head ${userFullName} logged out of the system`;

    try {
      const { error: logError } = await supabase
        .from('activity_logs')
        .insert([
          {
            activity_type: 'logout',
            description: activityDescription,
          },
        ]);
      if (logError) {
        console.error('Error logging activity:', logError);
      } else {
        console.log('Logout activity logged successfully');
      }
    } catch (err) {
      console.error('Error during logout activity logging:', err);
    }
  };

  // Handle logout logic
  const handleLogout = async () => {
    // Log the logout activity in Supabase
    await logLogoutActivity();

    // Clear the user data from localStorage
    localStorage.removeItem('user');
    console.log('Logged out and cleared local storage');

    // Update state and redirect to the login page
    setIsAuthenticated(false);
    navigate('/');

    // Close the logout modal
    setShowLogoutModal(false);
  };

  if (!isAuthenticated) return null; // Prevent rendering if not authenticated

  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
      <CDBSidebar textColor="#fff" backgroundColor="#222">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a href="/OICDashboard" className="text-decoration-none" style={{ color: 'inherit' }}>
            Pass Slip System
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink exact to="/OICDashboard" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/OICSliprequest" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="table">Slip Requests</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/OICRequestOverview" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="exclamation-circle">Request Overview</CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <Button
            variant="danger"
            onClick={() => setShowLogoutModal(true)}
            style={{ width: '100%', padding: '10px 0' }}
          >
            Logout
          </Button>
        </CDBSidebarFooter>
      </CDBSidebar>

      {/* Enhanced Confirm Logout Modal */}
      <Modal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        centered
        dialogClassName="modal-90w"
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <i className="fa fa-exclamation-triangle fa-3x text-warning mb-3"></i>
            <h4 className="mb-3">Are you sure you want to logout?</h4>
            <p className="text-muted">You will be redirected to the Login page.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLogoutModal(false)} className="me-2">
            Cancel
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sidebar;
