import React, { useState, useEffect } from 'react';
import { CDBSidebar, CDBSidebarContent, CDBSidebarFooter, CDBSidebarHeader, CDBSidebarMenu, CDBSidebarMenuItem } from 'cdbreact';
import { NavLink, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { supabase } from '../supabaseClient'; // Import Supabase client

const Sidebar = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isManageOpen, setIsManageOpen] = useState(false); // State to handle dropdown visibility
  const [profileData, setProfileData] = useState(null); // State to store profile info
  const [dhName, setDhName] = useState(''); 
  const navigate = useNavigate();

  // Fetch user data from localStorage and profile information
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      fetchProfileData(user.id); // Fetch profile data based on user ID
    } else {
      console.log('No user found');
    }
  }, []);

  const fetchProfileData = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('department_head') // Adjust the table name if necessary
        .select('firstname, lastname, profile_picture')
        .eq('id', userId) // Use the logged-in user ID
        .single(); // Fetch single record
      if (error) throw error;
      setProfileData(data);
      setDhName(`${data.firstname} ${data.lastname}`); // Set department head's full name for logging
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  const handleLogout = async () => {
    // Log the logout activity in Supabase
    const activityDescription = `Department/Head ${dhName} logged out of the system`;

    try {
      const { error: logError } = await supabase
        .from('activity_logs')
        .insert([
          {
            activity_type: 'logout',
            description: activityDescription,
          },
        ]);

      if (logError) {
        console.error('Error logging activity:', logError);
      } else {
        console.log('Logout activity logged successfully');
      }
    } catch (err) {
      console.error('Error during logout activity logging:', err);
    }

    // Clear local storage and navigate to the login page
    localStorage.removeItem('user');
    console.log('Logged out and cleared local storage');
    navigate('/');
    setShowLogoutModal(false);
  };

  // Navigate to the profile page when the profile picture is clicked
  const handleProfileClick = () => {
    navigate('/DHProfile');
  };

  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
      <CDBSidebar textColor="#fff" backgroundColor="#222">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          {/* Display profile picture and full name in a vertical layout */}
          {profileData && (
            <div className="d-flex flex-column align-items-center p-3">
              <img
                src={profileData.profile_picture || 'default-profile-pic.jpg'} // Use a default image if profile picture is not set
                alt="Profile"
                className="rounded-circle"
                onClick={handleProfileClick} // Make the profile picture clickable
                style={{
                  width: '100px', // Adjusted width
                  height: '100px', // Adjusted height
                  marginBottom: '10px',
                  objectFit: 'cover', // Prevent stretching and maintain aspect ratio
                  cursor: 'pointer', // Add pointer cursor for better UX
                }}
              />
              <div>
                <h6 className="text-white mb-0">{`${profileData.firstname} ${profileData.lastname}`}</h6>
              </div>
              <a href="/admin-dashboard" className="text-decoration-none text-white mt-2" style={{ fontSize: '1.1rem' }}>
                Pass Slip System
              </a>
            </div>
          )}
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink exact to="/DepartmentHD" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/DHSliprequest" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="table">Slip Requests</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/DHhistory" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="exclamation-circle">Request Overview</CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <Button
            variant="danger"
            onClick={() => setShowLogoutModal(true)}
            style={{ width: '100%', padding: '10px 0' }}
          >
            Logout
          </Button>
        </CDBSidebarFooter>
      </CDBSidebar>

      {/* Enhanced Confirm Logout Modal */}
      <Modal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        centered
        dialogClassName="modal-90w"
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <i className="fa fa-exclamation-triangle fa-3x text-warning mb-3"></i>
            <h4 className="mb-3">Are you sure you want to logout?</h4>
            <p className="text-muted">You will be redirected to the Login page.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLogoutModal(false)} className="me-2">
            Cancel
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sidebar;
