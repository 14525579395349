import React, { useState, useEffect } from 'react';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../admin_components/Sidebar';
import Header from '../admin_components/Header';
import { supabase } from '../supabaseClient';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import logo from '../images/evsu-logo.png';
import second_logo from '../images/second_logo.png';



const Report = () => {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const { data, error } = await supabase
                    .from('pass_slip_req')
                    .select('employee_id, employee_name, time_in, time_out, destination, approved_by, purpose, expected_duration, date')
                    .eq('status', 'Approved by the Head Office and Admin');

                if (error) {
                    throw error;
                }

                data.sort((a, b) => a.employee_id - b.employee_id);

                const groupedRequests = data.reduce((acc, request) => {
                    const { employee_id, employee_name, destination, time_in, time_out, approved_by, purpose, expected_duration, date } = request;
                    if (!acc[employee_id]) {
                        acc[employee_id] = {
                            employee_id,
                            employee_name,
                            total_expected_duration: 0,
                            requests: [],
                            dates: [],
                        };
                    }
                    acc[employee_id].total_expected_duration += parseInt(expected_duration);
                    acc[employee_id].requests.push({ destination, purpose, expected_duration, date, time_in, time_out, approved_by }); // Add time_in and time_out here
                    acc[employee_id].dates.push(formatDate(date));
                    return acc;
                }, {});

                const allRequests = Object.values(groupedRequests);
                setRequests(allRequests);
                setFilteredRequests(allRequests); // Initialize filtered requests with all requests
            } catch (error) {
                console.error('Error fetching requests:', error);
            } finally {
                setLoading(false);
            }
        };


        fetchRequests();
    }, []);

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        return date.toLocaleString();
    };

    const convertDuration = (minutes) => {
        const hrs = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hrs} hrs ${mins} min`;
    };

    const calculateTotalHoursSpent = (time_in, time_out) => {
        if (!time_in || !time_out) return 'N/A';
        const timeInDate = new Date(time_in);
        const timeOutDate = new Date(time_out);
        const diff = (timeOutDate - timeInDate) / (1000 * 60 * 60); // difference in hours
        const hours = Math.floor(diff);
        const minutes = Math.round((diff - hours) * 60); // Convert remaining fraction of hour to minutes
        return `${hours} hr ${minutes} min`;
    };

    const handleFilter = () => {
        // Parse the fromDate and toDate as date objects for comparison
        const fromDateParsed = new Date(fromDate);
        const toDateParsed = new Date(toDate);

        // Check if both dates are valid
        if (!fromDateParsed.getTime() || !toDateParsed.getTime()) {
            alert('Please select valid From and To dates');
            return;
        }

        // Filter requests to show only those within the selected date range
        const filtered = requests.reduce((acc, request) => {
            const employeeRequests = request.requests.filter(req => {
                const requestDate = new Date(req.date);
                return requestDate >= fromDateParsed && requestDate <= toDateParsed;
            });

            // If there are filtered requests for this employee, add them to the accumulator
            if (employeeRequests.length > 0) {
                acc.push({
                    ...request,
                    requests: employeeRequests,  // Keep only the requests within the date range
                });
            }

            return acc;
        }, []);

        // Sort filtered requests by date (descending order)
        const sortedFilteredRequests = filtered.sort((a, b) => {
            const dateA = new Date(a.requests[0].date);
            const dateB = new Date(b.requests[0].date);
            return dateB - dateA; // Sorting in descending order (latest date first)
        });

        setFilteredRequests(sortedFilteredRequests);
    };


    // Helper function to format time in 24-hour format without converting to local time
    function formatTime24Hrs(timestamp) {
        if (!timestamp) return 'N/A'; // Return 'N/A' if timestamp is null or empty

        const date = new Date(timestamp);
        const options = { hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'UTC' };

        return date.toLocaleTimeString('en-US', options); // 24-hour format in UTC
    }


    const exportPDF = () => {
        const input = document.getElementById('table-to-pdf');
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('portrait', 'pt', 'a4');
            const imgWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const margin = 30;
            const headerHeight = 100;  // Header height (this is for logos and title)
            const footerHeight = 50;
            const contentHeight = pageHeight - headerHeight - footerHeight;
            let heightLeft = imgHeight;
            let position = headerHeight;

            const addHeader = () => {
                // Add first logo
                pdf.addImage(logo, 'PNG', margin, 10, 50, 50); // Adjust logo size and position

                // Add second logo at the opposite side
                const secondLogoXPosition = imgWidth - margin - 50; // Adjust the X position for the second logo
                pdf.addImage(second_logo, 'PNG', secondLogoXPosition, 10, 50, 50); // Adjust logo size and position

                // Get the current date and time
                const currentDate = new Date();
                const formattedDate = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;

                pdf.setFontSize(14);
                pdf.text('Republic of the Philippines', imgWidth / 2, 30, { align: 'center' });

                // Set text color to red for "EASTERN VISAYAS STATE UNIVERSITY"
                pdf.setTextColor(255, 0, 0); // Red color in RGB
                pdf.setFontSize(16);
                pdf.text('EASTERN VISAYAS STATE UNIVERSITY', imgWidth / 2, 50, { align: 'center' });

                // Reset text color to black for the rest
                pdf.setTextColor(0, 0, 0); // Black color

                pdf.setFontSize(12);
                pdf.text('Ormoc City', imgWidth / 2, 70, { align: 'center' });
                pdf.text('EVSU ORMOC CAMPUS', imgWidth / 2, 85, { align: 'center' });

                // Add current date below 'EVSU ORMOC CAMPUS'
                // Get the width of the date text
                const dateWidth = pdf.getTextWidth(`Date: ${formattedDate}`);

                // Calculate the X position for right alignment
                const dateXPosition = imgWidth - margin - dateWidth;

                pdf.setFontSize(10);
                pdf.text(`Date: ${formattedDate}`, dateXPosition, 100); // Align to the right

            };

            const addFooter = (pageNumber) => {
                pdf.setFontSize(10);
                pdf.text(`Page ${pageNumber}`, imgWidth / 2, pageHeight - margin, { align: 'center' });
            };

            let pageNumber = 1;

            // Add header
            addHeader();

            // Add 1-line space between the header and the table
            position = 120; // Adjusted position for a 1-line space (change as necessary)

            // Add the table image (content)
            pdf.addImage(imgData, 'PNG', margin, position, imgWidth - margin * 2, imgHeight);

            // Add footer
            addFooter(pageNumber);
            heightLeft -= contentHeight;

            while (heightLeft > 0) {
                pdf.addPage();
                pageNumber++;
                position = margin;

                addHeader();

                // Add 1-line space between the header and the table
                position = 120; // Adjusted position for a 1-line space (change as necessary)

                pdf.addImage(imgData, 'PNG', margin, position, imgWidth - margin * 2, imgHeight);
                addFooter(pageNumber);

                heightLeft -= contentHeight;
            }

            pdf.save('Report.pdf');
        });
    };

    const handleSearch = () => {
        const searchQuery = searchTerm.toLowerCase().trim();

        const filteredBySearch = requests.filter(request => {
            return (
                request.employee_id.toString().includes(searchQuery) ||
                request.employee_name.toLowerCase().includes(searchQuery)
            );
        });

        setFilteredRequests(filteredBySearch);
    };


    return (
        <div style={styles.container}>
            <div style={styles.sidebar}>
                <Sidebar />
            </div>

            <div style={styles.mainContent}>
                <Header />

                <div style={styles.content}>
                    <h2 className="mb-4">Approved Slip Requests Report</h2>

                    <Form className="mb-4">
                        <Row className="align-items-center">
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Search by Employee ID or Name:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder="Enter Employee ID or Name"
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={2}>
                                <Button
                                    onClick={handleSearch}
                                    className="w-100 mt-4"
                                    style={{ backgroundColor: '#007bff', color: 'white', border: 'none', fontWeight: 'bold' }}
                                >
                                    Search
                                </Button>

                            </Col>
                        </Row>

                        <Row className="align-items-center mt-3">
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>From:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={fromDate}
                                        onChange={(e) => setFromDate(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>To:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={toDate}
                                        onChange={(e) => setToDate(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={2} className="d-flex justify-content-between">
                                <Button onClick={handleFilter} className="w-100 mt-4">
                                    Filter
                                </Button>
                            </Col>

                            <Col md={2}>
                                <Button
                                    onClick={exportPDF}
                                    className="w-100 mt-4"
                                    style={{ backgroundColor: '#dc3545', color: 'white', border: 'none', fontWeight: 'bold' }}
                                >
                                    <FontAwesomeIcon icon={faFilePdf} />
                                    Export PDF
                                </Button>

                            </Col>
                        </Row>
                    </Form>

                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <div id="table-to-pdf"> {/* Add this div to wrap your table */}
                            <Table striped bordered hover responsive className="text-center" style={{ width: '100%' }}>
                                <thead className="table-dark">
                                    <tr>
                                        <th>Date</th>
                                        <th>Employee ID</th>
                                        <th>Employee Name</th>
                                        <th>Destination</th>
                                        <th>Purpose</th>
                                        <th>Expected Duration</th>
                                        <th>Arrival</th>
                                        <th>Leave</th>
                                        <th>Total Hrs Spent</th>
                                        <th>Approved By</th> {/* New column for Approved By */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredRequests.length > 0 ? (
                                        // Sort requests by the 'dates' field in descending order (latest date first)
                                        filteredRequests.sort((a, b) => {
                                            const dateA = new Date(a.dates[0]);
                                            const dateB = new Date(b.dates[0]);
                                            return dateB - dateA; // Sorting in descending order (latest date first)
                                        }).map((request, index) => (
                                            <React.Fragment key={request.employee_id}>
                                                <tr>
                                                    {index === 0 || filteredRequests[index - 1].employee_id !== request.employee_id ? (
                                                        <>
                                                            <td style={styles.cellBorder}>{request.dates[0]}</td>
                                                            <td rowSpan={request.requests.length} style={styles.cellBorder}>{request.employee_id}</td>
                                                            <td rowSpan={request.requests.length} style={styles.cellBorder}>{request.employee_name}</td>
                                                        </>
                                                    ) : null}
                                                    <td style={styles.cellBorder}>{request.requests[0].destination}</td>
                                                    <td style={styles.cellBorder}>{request.requests[0].purpose}</td>
                                                    <td style={styles.cellBorder}>{convertDuration(request.requests[0].expected_duration)}</td>
                                                    <td style={styles.cellBorder}>{formatTime24Hrs(request.requests[0].time_in)}</td>
                                                    <td style={styles.cellBorder}>{formatTime24Hrs(request.requests[0].time_out)}</td>
                                                    <td style={styles.cellBorder}>{calculateTotalHoursSpent(request.requests[0].time_out, request.requests[0].time_in)}</td> {/* Total Hrs Spent */}
                                                    <td style={styles.cellBorder}>{request.requests[0].approved_by}</td> {/* Display Approved By */}
                                                </tr>
                                                {request.requests.slice(1).map((req, subIndex) => (
                                                    <tr key={subIndex}>
                                                        <td style={styles.cellBorder}>{request.dates[subIndex + 1]}</td>
                                                        <td style={styles.cellBorder}>{req.destination}</td>
                                                        <td style={styles.cellBorder}>{req.purpose}</td>
                                                        <td style={styles.cellBorder}>{convertDuration(req.expected_duration)}</td>
                                                        <td style={styles.cellBorder}>{formatTime24Hrs(req.time_in)}</td>
                                                        <td style={styles.cellBorder}>{formatTime24Hrs(req.time_out)}</td>
                                                        <td style={styles.cellBorder}>{calculateTotalHoursSpent(req.time_out, req.time_in)}</td> {/* Total Hrs Spent */}
                                                        <td style={styles.cellBorder}>{req.approved_by}</td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="10" className="text-center">No approved requests found.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>



                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        height: '100vh',
    },
    sidebar: {
        width: '250px', // Adjust the width of the sidebar as needed
        backgroundColor: '#f8f9fa', // Background color for sidebar
    },
    mainContent: {
        marginLeft: 15,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flexGrow: 1,
        overflowY: 'auto', // Enable vertical scrolling
        padding: '20px', // Padding around the content
    },
    cellBorder: {
        border: '3px solid black', // Light gray border for cells
    },
};

export default Report;
