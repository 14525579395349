import React, { useState, useEffect } from 'react';
import Sidebar from '../OIC_components/Sidebar'; // Adjusted path for OIC-specific sidebar
import Header from '../OIC_components/Header'; // Adjusted path for OIC-specific header
import { Card, Row, Col, Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import { FaClipboardList, FaCheckCircle } from 'react-icons/fa'; // Import the icons

const OICDashboard = () => {
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [metrics, setMetrics] = useState({
    pendingRequests: 0,
    approvedRequests: 0,
    totalOICs: 0,
    totalSecurityPersonnel: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || user.role !== 'Acting Department Head/Head Office') {
      navigate('/');
    }
  }, [navigate]);

  const handleCloseAddUserModal = () => setShowAddUserModal(false);
  const handleShowAddUserModal = () => setShowAddUserModal(true);

  const handleAddSecurityPersonnel = () => {
    navigate('/ManageSecurityPersonnel'); // Adjust navigation as needed
  };

  const handleAddOIC = () => {
    navigate('/ManageOIC'); // Adjust navigation as needed
  };

  const handleHomeClick = () => {
    navigate('/oic-dashboard'); // Adjust navigation as needed
  };

  const fetchMetrics = async () => {
    const user = JSON.parse(localStorage.getItem('user'));

    // Fetch the department of the logged-in Officer in Charge (OIC)
    const { data: oicData, error: soicError } = await supabase
      .from('officer_in_charge')
      .select('department')
      .eq('id', user.id)
      .single();

    if (soicError) {
      console.error('Error fetching office:', soicError);
      return;
    }

    const userOffice = oicData.department;
    const today = new Date();
    const todayDateString = today.toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format

    // Fetch pending requests filtered by the user's office and today's date
    const { data: pendingData, error: pendingError } = await supabase
      .from('pass_slip_req')
      .select('*')
      .eq('status', 'Pending')
      .eq('department', userOffice)
      .filter('date', 'gte', `${todayDateString} 00:00:00`)
      .filter('date', 'lte', `${todayDateString} 23:59:59`);

    // Fetch approved requests filtered by the user's office
    const { data: approvedData, error: approvedError } = await supabase
      .from('pass_slip_req')
      .select('*')
      .eq('status', 'Approved by the Head Office and Admin')
      .eq('department', userOffice);

    const { data: oics, error: oicError } = await supabase
      .from('officer_in_charge')
      .select('*');

    const { data: securityPersonnel, error: securityPersonnelError } = await supabase
      .from('security_personnel')
      .select('*');

    if (pendingError || approvedError || oicError || securityPersonnelError) {
      console.error('Error fetching data', pendingError || approvedError || oicError || securityPersonnelError);
    } else {
      setMetrics({
        pendingRequests: pendingData.length,
        approvedRequests: approvedData.length,
        totalOICs: oics.length,
        totalSecurityPersonnel: securityPersonnel.length,
      });
    }
  };

  const handlePendingRequestsClick = () => {
    navigate('/OICSliprequest'); // Adjust to the OIC's pending requests page
  };

  const handleApprovedRequestsClick = () => {
    navigate('/OICRequestOverview'); // Adjust to the OIC's approved requests page
  };

  useEffect(() => {
    fetchMetrics();
  }, []);

  return (
    <div
      className="dashboard-container"
      style={{
        display: 'flex',
        backgroundColor: '#f4f4f4',
        color: '#000',
        minHeight: '100vh',
      }}
    >
      <Sidebar />

      <div style={{ flex: 1 }}>
        <Header onHomeClick={handleHomeClick} />

        <main
      className="main-content"
      style={{
        backgroundColor: '#ffffff',
        padding: '30px',
        borderRadius: '12px',
        margin: '20px',
        color: '#000',
        boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
      }}
    >
      <h1 style={{ fontWeight: '600', color: '#333' }}>Acting Department Head/Head Office Dashboard</h1>

      <Row>
        {/* Pending Requests Card */}
        <Col md={3} className="mb-4">
          <Card onClick={handlePendingRequestsClick} style={{ cursor: 'pointer', borderRadius: '12px', boxShadow: '0 4px 10px rgba(0,0,0,0.1)', transition: 'transform 0.3s ease' }}>
            <Card.Body style={{ textAlign: 'center', padding: '20px' }}>
              <FaClipboardList style={{ fontSize: '2.5rem', color: '#007bff', marginBottom: '15px' }} />
              <Card.Title style={{ fontSize: '1.2rem', fontWeight: '600' }}>Pending Employee Requests</Card.Title>
              <Card.Text style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{metrics.pendingRequests}</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Approved Requests Card */}
        <Col md={3} className="mb-4">
          <Card onClick={handleApprovedRequestsClick} style={{ cursor: 'pointer', borderRadius: '12px', boxShadow: '0 4px 10px rgba(0,0,0,0.1)', transition: 'transform 0.3s ease' }}>
            <Card.Body style={{ textAlign: 'center', padding: '20px' }}>
              <FaCheckCircle style={{ fontSize: '2.5rem', color: '#28a745', marginBottom: '15px' }} />
              <Card.Title style={{ fontSize: '1.2rem', fontWeight: '600' }}>Approved Requests</Card.Title>
              <Card.Text style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{metrics.approvedRequests}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </main>

        <Modal show={showAddUserModal} onHide={handleCloseAddUserModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button
              variant="primary"
              className="w-100 mb-2"
              onClick={handleAddSecurityPersonnel}
            >
              Add Security Personnel
            </Button>
            <Button
              variant="secondary"
              className="w-100"
              onClick={handleAddOIC}
            >
              Add Officer in Charge
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAddUserModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default OICDashboard;
