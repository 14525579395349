import React, { useState, useEffect } from 'react';
import Sidebar from '../OIC_components_admin/Sidebar';
import Header from '../OIC_components_admin/Header';
import { Card, Button, Row, Col, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import { FaClipboardList, FaCheckCircle } from 'react-icons/fa'; // Import icons

const OICDashboardAdmin = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [metrics, setMetrics] = useState({
        pendingRequests: 0,
        pendingApprovalByAdmin: 0, // New metric for pending requests for admin approval
        approvedRequests: 0,
        totalDepartmentHeads: 0,
        totalSecurityPersonnel: 0,
    });

    const navigate = useNavigate();

    const handleCloseAddUserModal = () => setShowAddUserModal(false);
    const handleShowAddUserModal = () => setShowAddUserModal(true);

    const handleAddSecurityPersonnel = () => {
        navigate('/ManageSecurityPersonnel');
    };

    const handleAddDepartmentHead = () => {
        navigate('/ManageDepartmentHead');
    };

    const handleHomeClick = () => {
        navigate('/OICDashboardAdmin');
    };

    const handlePendingRequestsClick = () => {
        navigate('/OICSlipReqAdmin');
    };

    const handleApprovedRequestsClick = () => {
        navigate('/AdminOICRequestOverview');
    };

    const fetchMetrics = async () => {
        try {
            const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format

            // Fetch pending requests approved by the Head Office only, for the current date
            const { data: pendingRequests, count: pendingCount, error: pendingError } = await supabase
                .from('pass_slip_req')
                .select('*', { count: 'exact' })
                .eq('status', 'Approved by the Head Office')
                .filter('created_at', 'eq', currentDate); // Extract only the date for comparison

            if (pendingError) {
                console.error('Error fetching pending requests:', pendingError);
            }

            // Fetch pending requests that need approval by the Acting Admin
            const { data: pendingApprovalByAdmin, count: pendingApprovalCount, error: pendingApprovalError } = await supabase
                .from('pass_slip_req')
                .select('*', { count: 'exact' })
                .eq('status', 'Approved by the Head Office') // Approved by Head Office but pending admin approval
                .eq('admin_approval', false); // Assuming there is an `admin_approval` field to track admin's approval

            if (pendingApprovalError) {
                console.error('Error fetching pending approval by admin:', pendingApprovalError);
            }

            // Fetch approved requests with full admin approval
            const { data: approvedRequests, count: approvedCount, error: approvedError } = await supabase
                .from('pass_slip_req')
                .select('*', { count: 'exact' })
                .eq('status', 'Approved by the Head Office and Admin');

            if (approvedError) {
                console.error('Error fetching approved requests:', approvedError);
            }

            // Fetch total department heads
            const { data: departmentHeads, count: departmentHeadCount, error: deptHeadError } = await supabase
                .from('department_head')
                .select('*', { count: 'exact' });

            if (deptHeadError) {
                console.error('Error fetching department heads:', deptHeadError);
            }

            // Fetch total security personnel
            const { data: securityPersonnel, count: securityPersonnelCount, error: secPersonnelError } = await supabase
                .from('security_personnel')
                .select('*', { count: 'exact' });

            if (secPersonnelError) {
                console.error('Error fetching security personnel:', secPersonnelError);
            }

            // Set metrics state with results
            setMetrics({
                pendingRequests: pendingCount || 0,
                pendingApprovalByAdmin: pendingApprovalCount || 0, // Set pending approval by admin count
                approvedRequests: approvedCount || 0,
                totalDepartmentHeads: departmentHeadCount || 0,
                totalSecurityPersonnel: securityPersonnelCount || 0,
            });
        } catch (error) {
            console.error('Error fetching metrics:', error);
        }
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user || user.role !== 'Acting Administrative') {
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        fetchMetrics();
    }, []);

    return (
        <div
            className="dashboard-container"
            style={{
                display: 'flex',
                backgroundColor: '#f4f4f4',
                color: '#000',
                minHeight: '100vh',
            }}
        >
            <Sidebar />

            <div style={{ flex: 1 }}>
                <Header onHomeClick={handleHomeClick} />

                <main
      className="main-content"
      style={{
        backgroundColor: '#ffffff',
        padding: '30px',
        borderRadius: '12px',
        margin: '20px',
        color: '#000',
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
      }}
    >
      <h1 style={{ fontWeight: '600', color: '#333' }}>Acting Admin Dashboard</h1>

      <Row className="mb-4">
        {/* Pending Requests Card */}
        <Col md={3} className="mb-4">
          <Card
            onClick={handlePendingRequestsClick}
            style={{
              cursor: 'pointer',
              borderRadius: '12px',
              boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            }}
            className="card-hover"
          >
            <Card.Body style={{ textAlign: 'center', padding: '20px' }}>
              <FaClipboardList style={{ fontSize: '2.5rem', color: '#007bff', marginBottom: '15px' }} />
              <Card.Title style={{ fontSize: '1.2rem', fontWeight: '600' }}>Pending Employee Requests</Card.Title>
              <Card.Text style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{metrics.pendingRequests}</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Approved Requests Card */}
        <Col md={3} className="mb-4">
          <Card
            onClick={handleApprovedRequestsClick}
            style={{
              cursor: 'pointer',
              borderRadius: '12px',
              boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            }}
            className="card-hover"
          >
            <Card.Body style={{ textAlign: 'center', padding: '20px' }}>
              <FaCheckCircle style={{ fontSize: '2.5rem', color: '#28a745', marginBottom: '15px' }} />
              <Card.Title style={{ fontSize: '1.2rem', fontWeight: '600' }}>Approved Requests</Card.Title>
              <Card.Text style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{metrics.approvedRequests}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </main>

                <Modal show={showAddUserModal} onHide={handleCloseAddUserModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Manage User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Button
                            variant="primary"
                            className="w-100 mb-2"
                            onClick={handleAddSecurityPersonnel}
                        >
                            Security Personnel
                        </Button>
                        <Button
                            variant="secondary"
                            className="w-100"
                            onClick={handleAddDepartmentHead}
                        >
                            Department Head
                        </Button>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseAddUserModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default OICDashboardAdmin;
