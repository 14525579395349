import React, { useState, useEffect } from 'react';
import Sidebar from '../admin_components/Sidebar';
import Header from '../admin_components/Header';
import { Card, Button, Row, Col, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/AdminDashboard.css';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import { FaClipboardList, FaCheckCircle, FaUsers, FaShieldAlt, FaArrowRight } from 'react-icons/fa'; // Font Awesome icons

const AdminDashboard = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [metrics, setMetrics] = useState({
    pendingRequests: 0,
    approvedRequests: 0,
    totalDepartmentHeads: 0,
    totalSecurityPersonnel: 0,
  });
  const [activityLogs, setActivityLogs] = useState([]); // State for activity logs
  const [showAllLogs, setShowAllLogs] = useState(false); // State to control showing all logs

  const navigate = useNavigate();

  const handleViewStatistics = () => {
    navigate('/Report');
  };

  const handleCloseAddUserModal = () => setShowAddUserModal(false);
  const handleShowAddUserModal = () => setShowAddUserModal(true);
  const [hoverManageUsers, setHoverManageUsers] = useState(false); // Hover state for Manage Users button
  const [hoverViewReport, setHoverViewReport] = useState(false); // Hover state for View Report button


  const buttonStyle = {
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  };

  const hoverStyle = {
    transform: 'translateY(-5px)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };

  // Define styles for card hover effect and transition for row/col animations

  const handleAddSecurityPersonnel = () => {
    navigate('/ManageSecurityPersonnel');
  };

  const handleAddDepartmentHead = () => {
    navigate('/ManageDepartmentHead');
  };

  const handleAddOIC = () => {
    navigate('/ManageOIC');
  };

  const handleHomeClick = () => {
    navigate('/admin-dashboard');
  };

  const handlePendingRequestsClick = () => {
    navigate('/SlipRequests');
  };

  const handleApprovedRequestsClick = () => {
    navigate('/RequestOverview');
  };

  const handleDepartmentHeadClick = () => {
    navigate('/ManageDepartmentHead');
  };

  const handleSecurityPersonnelClick = () => {
    navigate('/ManageSecurityPersonnel');
  };

  const fetchMetrics = async () => {
    try {
      // Get current date in UTC+8 (Philippine Time) format
      const currentDate = new Date().toLocaleString('en-US', {
        timeZone: 'Asia/Manila',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).split(',')[0]; // Get the date part in YYYY-MM-DD format

      console.log('Current Date (PHT):', currentDate);

      // Fetch pending requests approved by the Head Office only, for the current date
      const { data: pendingRequests, count: pendingCount, error: pendingError } = await supabase
        .from('pass_slip_req')
        .select('*', { count: 'exact' })
        .eq('status', 'Approved by the Head Office')
        .filter('created_at', 'eq', currentDate); // Extract only the date for comparison

      if (pendingError) {
        console.error('Error fetching pending requests:', pendingError);
      }


      // Fetch approved requests with full admin approval
      const { data: approvedRequests, count: approvedCount, error: approvedError } = await supabase
        .from('pass_slip_req')
        .select('*', { count: 'exact' })
        .eq('status', 'Approved by the Head Office and Admin');

      if (approvedError) {
        console.error('Error fetching approved requests:', approvedError);
      }

      // Fetch total department heads
      const { data: departmentHeads, count: departmentHeadCount, error: deptHeadError } = await supabase
        .from('department_head')
        .select('*', { count: 'exact' });

      if (deptHeadError) {
        console.error('Error fetching department heads:', deptHeadError);
      }

      // Fetch total security personnel
      const { data: securityPersonnel, count: securityPersonnelCount, error: secPersonnelError } = await supabase
        .from('security_personnel')
        .select('*', { count: 'exact' });

      if (secPersonnelError) {
        console.error('Error fetching security personnel:', secPersonnelError);
      }

      // Set metrics state with results
      setMetrics({
        pendingRequests: pendingCount || 0,
        approvedRequests: approvedCount || 0,
        totalDepartmentHeads: departmentHeadCount || 0,
        totalSecurityPersonnel: securityPersonnelCount || 0,
      });
    } catch (error) {
      console.error('Error fetching metrics:', error);
    }
  };

  const fetchActivityLogs = async () => {
    try {
      const { data, error } = await supabase
        .from('activity_logs')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching activity logs:', error);
        return;
      }
      setActivityLogs(data || []); // Store activity logs in state
    } catch (error) {
      console.error('Error fetching activity logs:', error);
    }
  };

  const handleSeeMoreClick = () => {
    // Navigate to ActivityLog.js page
    navigate('/Monitoring');
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || user.role !== 'admin') {
      navigate('/');
    }
    fetchMetrics();
    fetchActivityLogs(); // Fetch activity logs when component mounts
    const savedMode = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(savedMode);
  }, [navigate]);

  return (
    <div
      className="dashboard-container"
      style={{
        display: 'flex',
        backgroundColor: '#f4f4f4',
        color: '#000',
        minHeight: '100vh',
      }}
    >
      <Sidebar />

      <div style={{ flex: 1 }}>
        <Header onHomeClick={handleHomeClick} />

        <main
          className="main-content"
          style={{
            backgroundColor: '#ffffff',
            padding: '20px',
            borderRadius: '12px',
            margin: '20px',
            color: '#000',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          }}
        >
          <h1>Admin Dashboard</h1>

          <Row className="mb-4">
            <Col md={3} className="mb-4">
              <Card onClick={handlePendingRequestsClick} style={{ cursor: 'pointer' }}>
                <Card.Body>
                  <Card.Title>
                    <FaClipboardList style={{ marginRight: '8px', color: '#007bff' }} />
                    Pending Employee Requests
                  </Card.Title>
                  <Card.Text>{metrics.pendingRequests}</Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} className="mb-4">
              <Card onClick={handleApprovedRequestsClick} style={{ cursor: 'pointer' }}>
                <Card.Body>
                  <Card.Title>
                    <FaCheckCircle style={{ marginRight: '8px', color: '#28a745' }} />
                    Approved Requests
                  </Card.Title>
                  <Card.Text>{metrics.approvedRequests}</Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} className="mb-4">
              <Card onClick={handleDepartmentHeadClick} style={{ cursor: 'pointer' }}>
                <Card.Body>
                  <Card.Title>
                    <FaUsers style={{ marginRight: '8px', color: '#17a2b8' }} />
                    Total DH/Head Office
                  </Card.Title>
                  <Card.Text>{metrics.totalDepartmentHeads}</Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} className="mb-4">
              <Card onClick={handleSecurityPersonnelClick} style={{ cursor: 'pointer' }}>
                <Card.Body>
                  <Card.Title>
                    <FaShieldAlt style={{ marginRight: '8px', color: '#dc3545' }} />
                    Total Security Personnel
                  </Card.Title>
                  <Card.Text>{metrics.totalSecurityPersonnel}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Render limited Activity Logs (5 logs) */}
          <Row className="mb-4">
            {/* Activity Logs */}
            <Col md={8} className="mb-4">
              <Card style={{ boxShadow: '0 2px 6px rgba(0,0,0,0.15)' }}>
                <Card.Body>
                  <Card.Title>Real-time Monitoring</Card.Title>
                  {activityLogs.length === 0 ? (
                    <Card.Text>No activity</Card.Text>
                  ) : (
                    <div className="activity-log-container">
                      {activityLogs
                        .slice(0, showAllLogs ? activityLogs.length : 5)
                        .map((log) => (
                          <div
                            key={log.id}
                            className="activity-log-item mb-2 p-2"
                            style={{
                              border: '1px solid #ddd',
                              borderRadius: '6px',
                              backgroundColor: '#f9f9f9',
                              boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                              display: 'flex', // Align children in a row
                              justifyContent: 'space-between', // Space out the items
                              alignItems: 'center', // Vertically center the items
                            }}
                          >
                            <div className="activity-log-description" style={{ flex: 2 }}>
                              <p style={{ margin: 0, color: '#444', fontSize: '0.9em', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {log.description}
                              </p>
                            </div>

                            <div className="activity-log-header mb-1" style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                              <span
                                style={{
                                  fontSize: '0.85em',
                                  color: '#666',
                                  marginLeft: '10px', // Space between the activity type and date
                                }}
                              >
                                {new Date(log.created_at).toLocaleString()}
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                  {activityLogs.length > 5 && !showAllLogs && (
                    <Button variant="link" onClick={handleSeeMoreClick} size="sm">
                      See More
                    </Button>
                  )}
                </Card.Body>
              </Card>
            </Col>


            {/* Shortcuts */}
            <Col md={4} className="mb-4">
              <Card>
                <Card.Body>
                  <Card.Title>
                    <FaArrowRight style={{ marginRight: '8px', color: '#6c757d' }} />
                    Shortcuts
                  </Card.Title>
                  <Button
                    variant="primary"
                    className="w-100 mb-2"
                    style={hoverManageUsers ? hoverStyle : buttonStyle}
                    onMouseEnter={() => setHoverManageUsers(true)}
                    onMouseLeave={() => setHoverManageUsers(false)}
                    onClick={handleShowAddUserModal}
                  >
                    Manage Users
                  </Button>
                  <Button
                    variant="secondary"
                    className="w-100"
                    style={hoverViewReport ? hoverStyle : buttonStyle}
                    onMouseEnter={() => setHoverViewReport(true)}
                    onMouseLeave={() => setHoverViewReport(false)}
                    onClick={handleViewStatistics}
                  >
                    View Report
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </main>

        <Modal show={showAddUserModal} onHide={handleCloseAddUserModal}>
          <Modal.Header closeButton>
            <Modal.Title>Manage User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button
              variant="primary"
              className="w-100 mb-2"
              onClick={handleAddSecurityPersonnel}
            >
              Security Personnel
            </Button>
            <Button
              variant="secondary"
              className="w-100 mb-2"
              onClick={handleAddDepartmentHead}
            >
              Department Head
            </Button>
            <Button
              variant="primary"
              className="w-100"
              onClick={handleAddOIC}
            >
              Officer-in-Charge
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAddUserModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AdminDashboard;
