import React, { useState, useEffect } from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { supabase } from '../supabaseClient';

const Sidebar = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isManageOpen, setIsManageOpen] = useState(false);
  const [profileUrl, setProfileUrl] = useState(null);
  const [adminName, setAdminName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileData = async () => {
      const user = JSON.parse(localStorage.getItem('user'));

      if (user && user.role === 'admin') {
        try {
          const { data, error } = await supabase
            .from('admin')
            .select('profile_url, firstname, lastname')
            .eq('id', user.id)
            .single();

          if (error) {
            console.error('Error fetching profile data:', error);
          } else {
            setProfileUrl(data.profile_url || 'https://via.placeholder.com/150');
            setAdminName(`${data.firstname} ${data.lastname}`);
          }
        } catch (err) {
          console.error('Error fetching profile data:', err);
        }
      }
    };

    fetchProfileData();
  }, []);

  const handleLogout = async () => {
    
    const activityDescription = `Admin ${adminName} logged out of the system`;

    try {
      const { error: logError } = await supabase
        .from('activity_logs')
        .insert([
          {
            activity_type: 'logout',
            description: activityDescription,
          },
        ]);

      if (logError) {
        console.error('Error logging activity:', logError);
      } else {
        console.log('Logout activity logged successfully');
      }
    } catch (err) {
      console.error('Error during logout activity logging:', err);
    }

    // Clear local storage and navigate to the login page
    localStorage.removeItem('user');
    console.log('Logged out and cleared local storage');
    navigate('/');
    setShowLogoutModal(false);
  };

  const toggleManageDropdown = () => {
    setIsManageOpen(!isManageOpen);
  };

  const handleProfileClick = () => {
    navigate('/AdminProfile'); 
  };

  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
      <CDBSidebar textColor="#fff" backgroundColor="#222">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <div className="text-center">
            <img
              src={profileUrl}
              alt="Admin Profile"
              className="img-fluid rounded-circle"
              style={{
                width: '100px',
                height: '100px',
                objectFit: 'cover',
                marginBottom: '10px',
                cursor: 'pointer',
              }}
              onClick={handleProfileClick} // Navigate to profile when clicked
            />
            <div style={{ color: '#fff', fontSize: '16px' }} onClick={handleProfileClick}>
              {adminName} {/* Display full name */}
            </div>
          </div>
          <a href="/admin-dashboard" className="text-decoration-none" style={{ color: 'inherit' }}></a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink exact to="/admin-dashboard" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/SlipRequests" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="table">Slip Requests</CDBSidebarMenuItem>
            </NavLink>

            <NavLink exact to="/RequestOverview" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="exclamation-circle">Request Overview</CDBSidebarMenuItem>
            </NavLink>

    

            <div>
              <div onClick={toggleManageDropdown} style={{ cursor: 'pointer' }}>
                <CDBSidebarMenuItem icon="user">
                  Manage {isManageOpen ? <i className="fa fa-caret-up"></i> : <i className="fa fa-caret-down"></i>}
                </CDBSidebarMenuItem>
              </div>
              {isManageOpen && (
                <div style={{ marginLeft: '20px' }}>
                  <NavLink exact to="/ManageSecurityPersonnel" activeClassName="activeClicked">
                    <CDBSidebarMenuItem icon="shield-alt">Manage Security Personnel</CDBSidebarMenuItem>
                  </NavLink>
                  <NavLink exact to="/ManageDepartmentHead" activeClassName="activeClicked">
                    <CDBSidebarMenuItem icon="user-tie">Manage Department Head</CDBSidebarMenuItem>
                  </NavLink>
                  <NavLink exact to="/ManageEmployee" activeClassName="activeClicked">
                    <CDBSidebarMenuItem icon="users">Manage Employee</CDBSidebarMenuItem>
                  </NavLink>
                  <NavLink exact to="/ManageOIC" activeClassName="activeClicked">
                    <CDBSidebarMenuItem icon="user-shield">Manage OIC</CDBSidebarMenuItem>
                  </NavLink>
                </div>
              )}
            </div>

            <NavLink exact to="/ManualSlipInput" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="edit">Manual Slip Input</CDBSidebarMenuItem>
            </NavLink>

            <NavLink exact to="/Report" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="chart-bar">Report</CDBSidebarMenuItem>
            </NavLink>

             {/* Add Monitoring option to Sidebar */}
             <NavLink exact to="/Monitoring" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="eye">Monitoring</CDBSidebarMenuItem>
            </NavLink>

          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <Button
            variant="danger"
            onClick={() => setShowLogoutModal(true)}
            style={{ width: '100%', padding: '10px 0' }}
          >
            Logout
          </Button>
        </CDBSidebarFooter>
      </CDBSidebar>

      <Modal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <i className="fa fa-exclamation-triangle fa-3x text-warning mb-3"></i>
            <h4 className="mb-3">Are you sure you want to logout?</h4>
            <p className="text-muted">You will be redirected to the Login page.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLogoutModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sidebar;
