import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../admin_components/Sidebar';
import Header from '../admin_components/Header';
import { supabase } from '../supabaseClient';
import { FaEye, FaComment, FaEdit } from 'react-icons/fa';


const RequestOverview = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [requests, setRequests] = useState([]);
  const [comment, setComment] = useState('');
  const [updateField, setUpdateField] = useState('');
  const [timeOut, setTimeOut] = useState('');
  const [timeIn, setTimeIn] = useState('');

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    const { data, error } = await supabase
      .from('pass_slip_req')
      .select('*')
      .eq('status', 'Approved by the Head Office and Admin');

    if (error) {
      console.error('Error fetching requests:', error);
    } else {
      setRequests(data);
    }
  };

  const calculateStatus = (timeIn, timeOut, expectedDuration) => {
    if (!timeIn || !timeOut || !expectedDuration) return 'Unknown';
  
    try {
      // Parse the times and convert both to UTC
      const timeInDate = new Date(timeIn).toISOString(); // Convert to ISO string (UTC)
      const timeOutDate = new Date(timeOut).toISOString(); // Convert to ISO string (UTC)
      const expectedDurationMinutes = parseInt(expectedDuration);
  
      // Add the expected duration to timeOut
      const expectedEndTime = new Date(new Date(timeOutDate).getTime() + expectedDurationMinutes * 60000); // Add minutes to timeOut
  
      // Calculate the difference in time
      const timeInDateObj = new Date(timeInDate);
      const differenceInMs = expectedEndTime - timeInDateObj; // Difference in milliseconds
      const differenceInMinutes = differenceInMs / 60000; // Convert milliseconds to minutes
  
      if (differenceInMinutes > 0) {
        return 'On Time';
      } else if (differenceInMinutes === 0) {
        return 'On Time';
      } else {
        return 'Exceeded';
      }
    } catch (e) {
      console.error('Error calculating status:', e);
      return 'Error';
    }
  };
  

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleShowDetailModal = (request) => {
    setSelectedRequest(request);
    setShowDetailModal(true);
  };

  const handleCloseDetailModal = () => {
    setShowDetailModal(false);
    setSelectedRequest(null);
  };

  const handleShowCommentModal = (comment) => {
    setComment(comment);
    setShowCommentModal(true);
  };

  const handleCloseCommentModal = () => {
    setShowCommentModal(false);
    setComment('');
  };

  const handleShowUpdateModal = (request) => {
    setSelectedRequest(request);
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setUpdateField('');
  };
  const handleUpdateTime = async () => {
    if (!updateField || (updateField === 'timeOut' && !timeOut) || (updateField === 'timeIn' && !timeIn)) {
      alert("Please select a field and provide a valid time.");
      return;
    }

    const currentDate = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format
    const updatedData = {};

    if (updateField === 'timeOut') {
      updatedData.time_out = `${currentDate}T${timeOut}:00`;
    } else if (updateField === 'timeIn') {
      updatedData.time_in = `${currentDate}T${timeIn}:00`;
    }

    const { error } = await supabase
      .from('pass_slip_req')
      .update(updatedData)
      .eq('id', selectedRequest.id);

    if (error) {
      console.error('Error updating time:', error);
    } else {
      fetchRequests(); // Refresh the requests after updating
      handleCloseUpdateModal();
    }
  };

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : ''} ${mins > 0 ? `${mins} minute${mins > 1 ? 's' : ''}` : ''}`.trim();
  };

  return (
    <div className="dashboard-container" style={{ display: 'flex', minHeight: '100vh' }}>
      <Sidebar />

      <div style={{ flex: 1 }}>
        <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />

        <main
          className="main-content"
          style={{
            overflowY: 'auto',
            height: 'calc(100vh - 80px)',
            backgroundColor: isDarkMode ? '#444' : 'white',
            padding: '20px',
            borderRadius: '8px',
            margin: '20px',
            color: isDarkMode ? '#f0f0f0' : '#000',
          }}
        >
          <h1>Slip Request Overview</h1>
          <Table
            striped
            bordered
            hover
            variant={isDarkMode ? 'dark' : 'light'}
            style={{
              backgroundColor: isDarkMode ? '#555' : '#fff',
              color: isDarkMode ? '#f0f0f0' : '#000',
            }}
          >
            <thead>
              <tr>
                <th>Destination</th>
                <th>Purpose</th>
                <th>Employee Name</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {requests
                .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sorting by latest date to oldest
                .map(request => (
                  <tr
                    key={request.id}
                    style={{
                      backgroundColor:
                        request.status === 'Approved by the Head Office and Admin'
                          ? '#d4edda'
                          : '#f8d7da',
                      color:
                        request.status === 'Approved by the Head Office and Admin'
                          ? '#155724'
                          : '#721c24',
                    }}
                  >
                    <td>{request.destination}</td>
                    <td>{request.purpose}</td>
                    <td>{request.employee_name}</td>
                    <td>{request.status}</td>
                    <td className="d-flex justify-content-center align-items-center">
                      <Button
                        variant="info"
                        size="sm"
                        className="d-flex align-items-center me-2"
                        onClick={() => handleShowDetailModal(request)}
                      >
                        <FaEye className="me-1" /> View Details
                      </Button>
                      {request.comment && (
                        <>
                          <Button
                            variant="secondary"
                            size="sm"
                            className="d-flex align-items-center me-2"
                            onClick={() => handleShowCommentModal(request.comment)}
                          >
                            <FaComment className="me-1" /> Remarks
                          </Button>
                          <Button
                            variant="warning"
                            size="sm"
                            className="d-flex align-items-center"
                            onClick={() => handleShowUpdateModal(request)}
                          >
                            <FaEdit className="me-1" /> Update
                          </Button>
                        </>
                      )}
                    </td>

                  </tr>
                ))}
            </tbody>
          </Table>

          <Modal
            show={showDetailModal}
            onHide={handleCloseDetailModal}
            centered
          >
            <Modal.Body style={{ backgroundColor: isDarkMode ? '#333' : '#fff', color: isDarkMode ? '#f0f0f0' : '#000' }}>
              {selectedRequest && (
                <div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Purpose:</h5>
                    <p>{selectedRequest.purpose}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Employee Name:</h5>
                    <p>{selectedRequest.employee_name}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Status:</h5>
                    <p>{selectedRequest.status}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Employee Email:</h5>
                    <p>{selectedRequest.employee_email}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Department:</h5>
                    <p>{selectedRequest.department}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Phone Number:</h5>
                    <p>{selectedRequest.phone_number}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Destination:</h5>
                    <p>{selectedRequest.destination}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Date:</h5>
                    <p>{new Date(selectedRequest.date).toLocaleString()}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Expected Duration:</h5>
                    <p>{formatDuration(selectedRequest.expected_duration)}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Time In:</h5>
                    <p>{selectedRequest.time_in ? new Date(selectedRequest.time_in).toLocaleString() : 'Not Available'}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Time Out:</h5>
                    <p>{selectedRequest.time_out ? new Date(selectedRequest.time_out).toLocaleString() : 'Not Available'}</p>
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Duration Status:</h5>
                    <p>{calculateStatus(selectedRequest.time_in, selectedRequest.time_out, selectedRequest.expected_duration)}</p>
                  </div>
                  <div style={{ marginBottom: '15px', textAlign: 'center' }}>
                    <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Proof:</h5>
                    {selectedRequest.proof_url ? (
                      <img
                        src={selectedRequest.proof_url}
                        alt="Proof"
                        style={{
                          maxWidth: '100%',
                          maxHeight: '300px',
                          borderRadius: '8px',
                          border: `2px solid ${isDarkMode ? '#f0f0f0' : '#000'}`,
                          display: 'block',
                          margin: '0 auto',
                        }}
                      />
                    ) : (
                      <p>No proof available</p>
                    )}
                  </div>
                  {selectedRequest.signatory && (
                    <div style={{ marginTop: '15px', textAlign: 'center' }}>
                      <h5 style={{ fontWeight: 'bold', color: isDarkMode ? '#f0f0f0' : '#000' }}>Signatory:</h5>
                      <p>{selectedRequest.signatory}</p>
                    </div>
                  )}
                </div>
              )}
            </Modal.Body>
          </Modal>

          {/* Comment Modal */}
          <Modal show={showCommentModal} onHide={handleCloseCommentModal} centered>
            <Modal.Header closeButton style={{ backgroundColor: isDarkMode ? '#444' : '#f1f1f1' }}>
              <Modal.Title style={{ color: isDarkMode ? '#f0f0f0' : '#000' }}>Remarks</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: isDarkMode ? '#333' : '#fff', color: isDarkMode ? '#f0f0f0' : '#000' }}>
              <p>{comment}</p>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: isDarkMode ? '#444' : '#f1f1f1' }}>
              <Button variant="secondary" onClick={handleCloseCommentModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showUpdateModal} onHide={handleCloseUpdateModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Update Time</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="updateField">
                  <Form.Label>Select Field to Update</Form.Label>
                  <Form.Control
                    as="select"
                    value={updateField}
                    onChange={(e) => setUpdateField(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="timeOut">Time Out</option>
                    <option value="timeIn">Time In</option>
                  </Form.Control>
                </Form.Group>
                {updateField === 'timeOut' && (
                  <Form.Group controlId="timeOut">
                    <Form.Label>Time Out</Form.Label>
                    <Form.Control
                      type="time"
                      value={timeOut}
                      onChange={(e) => setTimeOut(e.target.value)}
                    />
                  </Form.Group>
                )}
                {updateField === 'timeIn' && (
                  <Form.Group controlId="timeIn">
                    <Form.Label>Time In</Form.Label>
                    <Form.Control
                      type="time"
                      value={timeIn}
                      onChange={(e) => setTimeIn(e.target.value)}
                    />
                  </Form.Group>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseUpdateModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleUpdateTime}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>
        </main>
      </div>
    </div>
  );
};

export default RequestOverview;
