import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../departmenthead_components/Sidebar';
import Header from '../departmenthead_components/Header';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com'; // Import EmailJS

const DHSlipRequest = () => {
  const [selectedSlip, setSelectedSlip] = useState(null);
  const [slips, setSlips] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const navigate = useNavigate();

  const [userFullName, setUserFullName] = useState('');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || user.role !== 'department_head') {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchSlips = async () => {
      const user = JSON.parse(localStorage.getItem('user'));

      if (user) {
        const { data: departmentHeadData, error: departmentError } = await supabase
          .from('department_head')
          .select('department, firstname, lastname')
          .eq('id', user.id)
          .single();

        if (!departmentError) {
          const { department, firstname, lastname } = departmentHeadData;

          // Store full name in state
          setUserFullName(`${firstname} ${lastname}`);

          console.log('User Department:', department); // Check the department

          const { data: passSlipsData, error: slipsError } = await supabase
            .from('pass_slip_req')
            .select('*')
            .eq('department', department)
            .eq('status', 'Pending');

          if (!slipsError) {
            console.log('Pass Slips Data:', passSlipsData); // Log the retrieved slips

            // Get the current date without time component
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0); // Reset time to midnight for comparison

            // Filter out slips with a date earlier than the current date
            const filteredSlips = passSlipsData.filter(slip => {
              const slipDate = new Date(slip.date);
              slipDate.setHours(0, 0, 0, 0); // Reset slip date time to midnight for comparison
              return slipDate >= currentDate;
            });

            // Sort remaining slips by date
            const sortedSlips = filteredSlips.sort((a, b) => new Date(b.date) - new Date(a.date));
            setSlips(sortedSlips);
          }
        } else {
          console.error('Error fetching department head data:', departmentError);
        }
      }
    };

    fetchSlips();
  }, []); // Empty dependency array means this will run on component mount


  const handleApprove = async (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
  
    // Check user object
    console.log(user);
  
    const { error } = await supabase
      .from('pass_slip_req')
      .update({
        status: 'Approved by the Head Office',
        approved_by: userFullName  // Update the approved_by field with the user's full name
      })
      .eq('id', id);
  
    if (!error) {
      setSlips((prevSlips) =>
        prevSlips.map((slip) =>
          slip.id === id ? { ...slip, status: 'Approved by the Head Office', approved_by: userFullName } : slip
        )
      );
  
      // Send email notification to the employee
      await sendEmailNotification(selectedSlip.employee_email, 'Approved');
  
      // Fetch Admin email and Department Head name for notification
      await sendAdminNotification(selectedSlip.employee_name);
  
      // Log the approval in the activity_logs table
      await logActivity(selectedSlip.employee_name);
  
      setShowConfirmationModal(false);
    } else {
      console.error('Error approving slip:', error);
    }
  };
  
  // Function to log the approval in the activity_logs table
  const logActivity = async (employeeName) => {
    const user = JSON.parse(localStorage.getItem('user'));
  
    // Insert the log into activity_logs table
    const { error } = await supabase
      .from('activity_logs')
      .insert([
        {
          activity_type: 'Approval',
          description: `Department/Head Office ${userFullName} Approved the Request of ${employeeName}`,
        },
      ]);
  
    if (error) {
      console.error('Error logging activity:', error);
    } else {
      console.log('Activity logged successfully');
    }
  };
  


  const sendEmailNotification = (toEmail, action) => {
    const message = action === 'Approved'
      ? `Your pass slip request has been approved by the department head, please wait for the approval of the Admin.`
      : `Your pass slip request has been declined by the department head`;

    const templateParams = {
      to_email: toEmail,
      reply_to: 'default@example.com', // Replace with your default email
      message: message,
    };

    emailjs.send('service_crla1iv', 'template_w1ck8re', templateParams, 'LtbIUA7B49TOggb24')
      .then((response) => {
        console.log('Email sent successfully:', response.text);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };

  const sendAdminNotification = async (employeeName) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const { data: departmentHeadData, error: departmentError } = await supabase
      .from('department_head')
      .select('lastname')
      .eq('email', user.email)
      .single();

    if (!departmentError) {
      const departmentHeadName = departmentHeadData.lastname;

      // Fetch admin email
      const { data: adminData, error: adminError } = await supabase
        .from('admin')
        .select('email')
        .single();

      if (!adminError && adminData) {
        const adminEmail = adminData.email;
        const message = `The Department Head, ${departmentHeadName}, has approved the request submitted by ${employeeName}. Please proceed on approving the request.`;

        const templateParams = {
          to_email: adminEmail,
          reply_to: 'default@example.com', // Replace with your default email
          message: message,
        };

        emailjs.send('service_crla1iv', 'template_w1ck8re', templateParams, 'LtbIUA7B49TOggb24')
          .then((response) => {
            console.log('Admin email sent successfully:', response.text);
          })
          .catch((error) => {
            console.error('Error sending admin email:', error);
          });
      }
    }
  };

  const handleReject = async (id) => {
    const { error } = await supabase
      .from('pass_slip_req')
      .update({ status: 'Rejected' })
      .eq('id', id);

    if (!error) {
      setSlips((prevSlips) =>
        prevSlips.map((slip) =>
          slip.id === id ? { ...slip, status: 'Rejected' } : slip
        )
      );

      // Send email notification to the employee
      await sendEmailNotification(selectedSlip.employee_email, 'Rejected');
      setShowRejectionModal(false);
    } else {
      console.error('Error rejecting slip:', error);
    }
  };

  const handleShowDetailModal = (slip) => {
    setSelectedSlip(slip);
    setShowDetailModal(true);
  };

  const handleCloseDetailModal = () => setShowDetailModal(false);
  const handleShowConfirmationModal = (slip) => {
    setSelectedSlip(slip);
    setShowConfirmationModal(true);
  };
 
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  return (
    <div className="dashboard-container" style={{ display: 'flex', backgroundColor: '#f4f4f4', color: '#000', minHeight: '100vh' }}>
      <Sidebar />
      <div style={{ flex: 1, overflow: 'hidden' }}>
        <Header />
        <main className="main-content" style={{
          backgroundColor: '#ffffff',
          padding: '20px',
          borderRadius: '12px',
          margin: '20px',
          color: '#000',
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          height: 'calc(100vh - 100px)', // Adjust height to fit your header
          overflowY: 'auto' // Enable vertical scrolling
        }}>
          <h1>Pass Slip Requests</h1>
          <Table striped bordered hover variant={'light'} style={{ backgroundColor: '#fff', color: '#333' }}>
            <thead>
              <tr>
                <th>Destination</th>
                <th>Purpose</th>
                <th>Employee Name</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {slips.filter(slip => slip.status === 'Pending').map((slip) => (
                <tr key={slip.id}>
                  <td>{slip.destination}</td>
                  <td>{slip.purpose}</td>
                  <td>{slip.employee_name}</td>
                  <td>{slip.status}</td>
                  <td className="d-flex justify-content-center">
                    <Button variant="info" size="sm" className="me-2" onClick={() => handleShowDetailModal(slip)}>
                      <i className="bi bi-eye"></i> View Details
                    </Button>
                    <Button variant="success" className="me-2" onClick={() => handleShowConfirmationModal(slip)} disabled={slip.status !== 'Pending'}>
                      <i className="bi bi-check"></i> Approve
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Detail Modal */}
          <Modal
            show={showDetailModal}
            onHide={handleCloseDetailModal}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Pass Slip Details</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: '#fff' }}>
              {selectedSlip ? (
                <div>
                  {/* Date Requested */}
                  <Card
                    style={{
                      marginBottom: '15px',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                      backgroundColor: '#f8f9fa',
                    }}
                  >
                    <Card.Body>
                      <Card.Title>Date Requested</Card.Title>
                      <Card.Text>{new Date(selectedSlip.date).toLocaleDateString()}</Card.Text>
                    </Card.Body>
                  </Card>

                  {/* Destination */}
                  <Card
                    style={{
                      marginBottom: '15px',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                      backgroundColor: '#f8f9fa',
                    }}
                  >
                    <Card.Body>
                      <Card.Title>Where to go / Destination</Card.Title>
                      <Card.Text>{selectedSlip.destination}</Card.Text>
                    </Card.Body>
                  </Card>

                  {/* Purpose */}
                  <Card
                    style={{
                      marginBottom: '15px',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                      backgroundColor: '#f8f9fa',
                    }}
                  >
                    <Card.Body>
                      <Card.Title>Purpose</Card.Title>
                      <Card.Text>{selectedSlip.purpose}</Card.Text>
                    </Card.Body>
                  </Card>

                  {/* Expected Duration */}
                  <Card
                    style={{
                      marginBottom: '15px',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                      backgroundColor: '#f8f9fa',
                    }}
                  >
                    <Card.Body>
                      <Card.Title style={{ color: '#000' }}>Expected Duration</Card.Title>
                      <Card.Text style={{ fontSize: '16px', color: '#000' }}>
                        {(() => {
                          if (!selectedSlip.expected_duration || selectedSlip.expected_duration <= 0) {
                            return 'N/A';
                          }
                          const hours = Math.floor(selectedSlip.expected_duration / 60);
                          const minutes = selectedSlip.expected_duration % 60;
                          return `${hours} hr${hours !== 1 ? 's' : ''} and ${minutes} min${minutes !== 1 ? 's' : ''}`;
                        })()}
                      </Card.Text>
                    </Card.Body>
                  </Card>

                   {/* Employee Name */}
                   <Card
                    style={{
                      marginBottom: '15px',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                      backgroundColor: '#f9f9f9',
                    }}
                  >
                    <Card.Body>
                      <Card.Title style={{ color: '#000' }}>Employee Name</Card.Title>
                      <Card.Text style={{ fontSize: '16px', color: '#000' }}>
                        {selectedSlip.employee_name}
                      </Card.Text>
                    </Card.Body>
                  </Card>

                  {/* Employee Email */}
                  <Card
                    style={{
                      marginBottom: '15px',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                      backgroundColor: '#f8f9fa',
                    }}
                  >
                    <Card.Body>
                      <Card.Title>Employee Email</Card.Title>
                      <Card.Text>{selectedSlip.employee_email}</Card.Text>
                    </Card.Body>
                  </Card>

                  {/* Department */}
                  <Card
                    style={{
                      marginBottom: '15px',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                      backgroundColor: '#f8f9fa',
                    }}
                  >
                    <Card.Body>
                      <Card.Title>Department</Card.Title>
                      <Card.Text>{selectedSlip.department}</Card.Text>
                    </Card.Body>
                  </Card>

                  {/* Phone Number */}
                  <Card
                    style={{
                      marginBottom: '15px',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                      backgroundColor: '#f8f9fa',
                    }}
                  >
                    <Card.Body>
                      <Card.Title>Phone Number</Card.Title>
                      <Card.Text>{selectedSlip.phone_number}</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              ) : (
                <p>No details available.</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDetailModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Confirmation Modal */}
          <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal}>
            <Modal.Header closeButton>
              <Modal.Title>Approve Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to approve this request?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseConfirmationModal}>
                Cancel
              </Button>
              <Button variant="success" onClick={() => handleApprove(selectedSlip.id)}>
                Approve
              </Button>
            </Modal.Footer>
          </Modal>

        </main>
      </div>
    </div>
  );
};

export default DHSlipRequest;
