import React, { useEffect, useState } from 'react';
import { Button, Table, Container, Modal } from 'react-bootstrap';
import { FaCheck, FaTimes } from 'react-icons/fa';
import Sidebar from '../admin_components/Sidebar';
import Header from '../admin_components/Header';
import { supabase } from '../supabaseClient';

const UserVerification = () => {
  const [unverifiedEmployees, setUnverifiedEmployees] = useState([]);
  const [unverifiedSecurity, setUnverifiedSecurity] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const fetchUnverifiedUsers = async () => {
      const { data: employees } = await supabase
        .from('employee')
        .select('*')
        .eq('is_verified', 0);

      const { data: securityPersonnel } = await supabase
        .from('security_personnel')
        .select('*')
        .eq('is_verified', 0);

      setUnverifiedEmployees(employees || []);
      setUnverifiedSecurity(securityPersonnel || []);
    };

    fetchUnverifiedUsers();
  }, []);

  const handleShowModal = (action, user, table) => {
    setSelectedAction({ action, table });
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleConfirmAction = async () => {
    const { action, table } = selectedAction;
    const { id } = selectedUser;

    if (action === 'verify') {
      await supabase
        .from(table)
        .update({ is_verified: 1 })
        .eq('id', id);

      if (table === 'employee') {
        setUnverifiedEmployees(prev => prev.filter(emp => emp.id !== id));
      } else {
        setUnverifiedSecurity(prev => prev.filter(sec => sec.id !== id));
      }
    } else if (action === 'reject') {
      if (table === 'employee') {
        setUnverifiedEmployees(prev => prev.filter(emp => emp.id !== id));
      } else {
        setUnverifiedSecurity(prev => prev.filter(sec => sec.id !== id));
      }
    }

    setShowModal(false);
    setSelectedAction(null);
    setSelectedUser(null);
  };

  return (
    <div
      className="dashboard-container"
      style={{
        display: 'flex',
        backgroundColor: '#f4f4f4',
        color: '#000',
        minHeight: '100vh',
      }}
    >
      <Sidebar />

      <div style={{ flex: 1, overflow: 'hidden' }}>
        <Header />
        <Container
          fluid
          style={{
            background: 'rgba(255, 255, 255, 0.15)',
            padding: '40px',
            borderRadius: '12px',
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.3)',
            backdropFilter: 'blur(10px)',
            height: 'calc(100vh - 80px)',
            overflowY: 'auto',
          }}
        >
          <h2 className="text-center mb-4" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
            User Verification
          </h2>

          <h4>Unverified Employees</h4>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Employee ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Department</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {unverifiedEmployees.map(emp => (
                <tr key={emp.id}>
                  <td>{emp.employee_id}</td>
                  <td>{`${emp.firstname} ${emp.lastname}`}</td>
                  <td>{emp.email}</td>
                  <td>{emp.department}</td>
                  <td>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Button
                        variant="success"
                        onClick={() => handleShowModal('verify', emp, 'employee')}
                      >
                        <FaCheck /> Verify
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleShowModal('reject', emp, 'employee')}
                      >
                        <FaTimes /> Reject
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <h4>Unverified Security Personnel</h4>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {unverifiedSecurity.map(sec => (
                <tr key={sec.id}>
                  <td>{`${sec.firstname} ${sec.lastname}`}</td>
                  <td>{sec.email}</td>
                  <td>{sec.role}</td>
                  <td>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Button
                        variant="success"
                        onClick={() => handleShowModal('verify', sec, 'security_personnel')}
                      >
                        <FaCheck /> Verify
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleShowModal('reject', sec, 'security_personnel')}
                      >
                        <FaTimes /> Reject
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedAction?.action === 'verify'
            ? 'Are you sure you want to verify this user?'
            : 'Are you sure you want to reject this user?'}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmAction}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserVerification;
