import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Button, Card, Container, Row, Col, Form } from 'react-bootstrap';
import Sidebar from '../departmenthead_components/Sidebar';
import Header from '../departmenthead_components/Header';

const DHProfile = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [adminInfo, setAdminInfo] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedInfo, setUpdatedInfo] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (!user || user.role !== 'department_head') {
      navigate('/');
    } else {
      fetchAdminData(user.id);
    }
  }, [navigate]);

  const fetchAdminData = async (id) => {
    try {
      const { data, error } = await supabase
        .from('department_head')
        .select('id, email, firstname, lastname, phone, profile_picture, department, role') // Include role
        .eq('id', id)
        .single();

      if (error) {
        console.error('Error fetching admin data:', error);
      } else {
        setAdminInfo(data);
        setUpdatedInfo({ ...data });
      }
    } catch (err) {
      console.error('Error fetching admin data:', err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedInfo({ ...updatedInfo, [name]: value });
  };

  const handleProfileImageChange = (e) => {
    setProfileImage(e.target.files[0]);
  };

  const handleUpdateProfile = async () => {
    try {
      let profileUrl = adminInfo.profile_picture;

      if (profileImage) {
        const { data: imageData, error: imageError } = await supabase.storage
          .from('pass_slip_images')
          .upload(`profile_pictures/${adminInfo.id}-${profileImage.name}`, profileImage, {
            cacheControl: '3600',
            upsert: true,
          });

        if (imageError) {
          console.error('Error uploading profile image:', imageError);
        } else {
          profileUrl = `https://nnxbuogphggkhgqcdfmj.supabase.co/storage/v1/object/public/pass_slip_images/${imageData.path}`;
          console.log('Uploaded image data:', imageData);  
        }
      }

      const { data, error } = await supabase
        .from('department_head')
        .update({
          firstname: updatedInfo.firstname,
          lastname: updatedInfo.lastname,
          phone: updatedInfo.phone,
          profile_picture: profileUrl,
        })
        .eq('id', adminInfo.id);

      if (error) {
        console.error('Error updating admin profile:', error);
      } else {
        console.log('Profile updated successfully!');
        setAdminInfo({ ...updatedInfo, profile_picture: profileUrl });
        setIsEditing(false);
      }
    } catch (err) {
      console.error('Error updating admin profile:', err);
    }
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div
      className="dashboard-container"
      style={{
        display: 'flex',
        backgroundColor: isDarkMode ? '#333' : '#f7f7f7',
        color: isDarkMode ? '#f0f0f0' : '#000',
        minHeight: '100vh',
      }}
    >
      <Sidebar />

      <div style={{ flex: 1 }}>
        <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />
        <Container className="p-5" style={{ maxWidth: '1200px' }}>
          <Card className="p-5 shadow-lg">
            <Row className="align-items-center">
              <Col md={4}>
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={adminInfo?.profile_picture || 'https://via.placeholder.com/150'}
                    alt="Profile"
                    className="img-fluid rounded-circle"
                    style={{ width: '300px', height: '300px', objectFit: 'contain', marginBottom: '20px' }}
                  />
                </div>
              </Col>
              <Col md={8}>
                {isEditing ? (
                  <Form>
                    <Form.Group className="mb-4">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstname"
                        value={updatedInfo.firstname || ''}
                        onChange={handleInputChange}
                        size="lg"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastname"
                        value={updatedInfo.lastname || ''}
                        onChange={handleInputChange}
                        size="lg"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone"
                        value={updatedInfo.phone || ''}
                        onChange={handleInputChange}
                        size="lg"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Label>Department</Form.Label>
                      <Form.Control
                        type="text"
                        name="department"
                        value={updatedInfo.department || ''}
                        onChange={handleInputChange}
                        size="lg"
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Label>Role</Form.Label>
                      <Form.Control
                        type="text"
                        name="role"
                        value={updatedInfo.role || ''} // Role input
                        onChange={handleInputChange}
                        size="lg"
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Label>Profile Picture</Form.Label>
                      <Form.Control type="file" onChange={handleProfileImageChange} />
                    </Form.Group>
                    <Button variant="success" onClick={handleUpdateProfile} className="mt-3" size="lg">
                      Save Changes
                    </Button>
                    <Button variant="secondary" onClick={() => setIsEditing(false)} className="mt-3 ms-3" size="lg">
                      Cancel
                    </Button>
                  </Form>
                ) : (
                  <>
                    <h2>{adminInfo?.firstname} {adminInfo?.lastname}</h2>
                    <p className="text-muted">{adminInfo?.email}</p>
                    <p>{adminInfo?.phone}</p>
                    <p><strong>Department: </strong>{adminInfo?.department}</p>
                    <p><strong>Role: </strong>{adminInfo?.role === 'department_head' ? 'Department Head / Head Office' : adminInfo?.role}</p>
                    <Button variant="primary" onClick={() => setIsEditing(true)} size="lg">
                      Edit Profile
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Card>
        </Container>
      </div>
    </div>
  );
};

export default DHProfile;
