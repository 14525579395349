import React, { useState, useEffect } from 'react';
import Sidebar from '../admin_components/Sidebar';
import Header from '../admin_components/Header';
import { Card, Row, Col } from 'react-bootstrap';
import { supabase } from '../supabaseClient';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Monitoring = () => {
  const [activityLogs, setActivityLogs] = useState([]);
  const [todaysRequests, setTodaysRequests] = useState(0);
  const [employeesLeftToday, setEmployeesLeftToday] = useState(0);
  const [employeesReturnedToday, setEmployeesReturnedToday] = useState(0);

  const formatDateTime = (datetime) => {
    const date = new Date(datetime);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // AM/PM format
    };
    return date.toLocaleString('en-US', options).replace(',', '');
  };

  const fetchActivityLogs = async () => {
    try {
      const { data, error } = await supabase
        .from('activity_logs')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching activity logs:', error);
        return;
      }

      // Format each activity's created_at field
      const formattedData = data.map(log => ({
        ...log,
        created_at: formatDateTime(log.created_at), // Format the date
      }));

      setActivityLogs(formattedData); // Set the formatted data
    } catch (error) {
      console.error('Error fetching activity logs:', error);
    }
  };
  const fetchMetrics = async () => {
    try {
      // Today's Request count from activity_logs with activity_type 'Pass Slip Request'
      const { data: requestData, error: requestError } = await supabase
        .from('activity_logs')
        .select('*')
        .eq('activity_type', 'Pass Slip Request') // Filter by 'Pass Slip Request'
        .filter('created_at', 'gte', new Date().toISOString().split('T')[0]) // Filter by today's date (start of the day)
        .filter('created_at', 'lt', new Date().toISOString().split('T')[0] + "T23:59:59") // Ensure it's only today's logs (before end of day)

      if (requestError) {
        console.error('Error fetching today\'s requests:', requestError);
      } else {
        setTodaysRequests(requestData.length);
      }

      // Employees Left Today (activity_type 'Leave')
      const { data: leftData, error: leftError } = await supabase
        .from('activity_logs')
        .select('*')
        .eq('activity_type', 'Leave') // Filter by 'Leave'
        .filter('created_at', 'gte', new Date().toISOString().split('T')[0]) // Filter by today's date (start of the day)
        .filter('created_at', 'lt', new Date().toISOString().split('T')[0] + "T23:59:59"); // Ensure it's only today's logs (before end of day)

      if (leftError) {
        console.error('Error fetching employees left today:', leftError);
      } else {
        setEmployeesLeftToday(leftData.length); // Set the count of employees who left today
      }

      // Employees Returned Today (activity_type 'Returned')
      const { data: returnedData, error: returnedError } = await supabase
        .from('activity_logs')
        .select('*')
        .eq('activity_type', 'Returned') // Filter by 'Returned'
        .filter('created_at', 'gte', new Date().toISOString().split('T')[0]) // Filter by today's date (start of the day)
        .filter('created_at', 'lt', new Date().toISOString().split('T')[0] + "T23:59:59"); // Ensure it's only today's logs (before end of day)

      if (returnedError) {
        console.error('Error fetching employees returned today:', returnedError);
      } else {
        setEmployeesReturnedToday(returnedData.length); // Set the count of employees who returned today
      }
    } catch (error) {
      console.error('Error fetching metrics:', error);
    }
  };


  useEffect(() => {
    fetchActivityLogs(); // Fetch activity logs when the page loads
    fetchMetrics(); // Fetch metrics for today's requests and employees left/returned
  }, []);

  return (
    <div
      className="dashboard-container"
      style={{
        display: 'flex',
        backgroundColor: '#f4f4f4',
        color: '#000',
        minHeight: '100vh',
      }}
    >
      <Sidebar />

      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100vh', // Make sure it takes up full height
        }}
      >
        <Header />

        <main
          className="main-content"
          style={{
            backgroundColor: '#ffffff',
            padding: '20px',
            borderRadius: '12px',
            margin: '20px',
            color: '#000',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            overflowY: 'auto', // Enable vertical scrolling for activity logs only
            flex: 1, // Allow the main section to take up the remaining space
            maxHeight: 'calc(100vh - 80px)', // Adjust the height to account for header
          }}
        >
          <h1>Real-time Monitoring</h1>

          {/* Metrics section outside scrollable area */}
          <Row>
          <Col md={4}>
              {/* Wrap the Card in Link to navigate to /TodaysReq */}
              <Link to="/TodaysReq" style={{ textDecoration: 'none' }}>
                <Card>
                  <Card.Body>
                    <Card.Title>Today's Request</Card.Title>
                    <Card.Text>{todaysRequests}</Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>

            <Col md={4}>
            <Link to="/EmployeeLeft" style={{ textDecoration: 'none' }}>
              <Card>
                <Card.Body>
                  <Card.Title>Employees Left Today</Card.Title>
                  <Card.Text>{employeesLeftToday}</Card.Text>
                </Card.Body>
              </Card>
              </Link>
            </Col>

            <Col md={4}>
            <Link to="/EmployeeReturned" style={{ textDecoration: 'none' }}>
              <Card>
                <Card.Body>
                  <Card.Title>Employees Returned Today</Card.Title>
                  <Card.Text>{employeesReturnedToday}</Card.Text>
                </Card.Body>
              </Card>
              </Link>
            </Col>
          </Row>

          {/* Activity logs section inside scrollable area */}
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body>
                  <Card.Title>Activity</Card.Title>

                  {/* Scrollable container for the logs */}
                  <div
                    style={{
                      overflowY: 'scroll', // Enable vertical scrolling for activity logs only
                      maxHeight: 'calc(100vh - 380px)', // Adjust height so activity logs don't overlap with metrics cards
                    }}
                  >
                    {activityLogs.length === 0 ? (
                      <Card.Text>No activity available</Card.Text>
                    ) : (
                      activityLogs.map((log) => (
                        <Card
                          key={log.id}
                          style={{
                            marginBottom: '10px',
                            padding: '10px', // Make the card smaller
                            fontSize: '14px', // Adjust font size
                          }}
                        >
                          <Card.Body>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between', // Space between elements
                                alignItems: 'center', // Align items vertically
                                fontSize: '14px', // Adjust font size for a single line
                              }}
                            >
                              <div style={{ flex: 2 }}>
                                <span>{log.description}</span>
                              </div>
                              <div style={{ flex: 1, textAlign: 'right' }}>
                                <small>{log.created_at}</small>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      ))
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </main>
      </div>
    </div>
  );
};

export default Monitoring;
