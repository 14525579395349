import React, { useState, useEffect } from 'react';
import Sidebar from '../admin_components/Sidebar'; // Import Sidebar component
import Header from '../admin_components/Header'; // Import Header component
import { supabase } from '../supabaseClient';
import { Table, Button, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const ManageDH = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [departmentHeads, setDepartmentHeads] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedHead, setSelectedHead] = useState(null);
  const [newHead, setNewHead] = useState({
    email: '',
    password: '',
    firstname: '',
    lastname: '',
    department: '',
    phone: '',
  });

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  // Function to fetch department head data
  const fetchAndSetDepartmentHeads = async () => {
    console.log('Fetching department head data...');
    try {
      const { data, error } = await supabase.from('department_head').select('*');
      if (error) {
        console.error('Error fetching department heads:', error);
        return;
      }
      console.log('Fetched data:', data);
      if (Array.isArray(data)) {
        setDepartmentHeads(data);
      } else {
        console.error('Unexpected data format:', data);
      }
    } catch (error) {
      console.error('Error fetching department heads:', error);
    }
  };

  useEffect(() => {
    // Check if user data is stored in localStorage
    const user = JSON.parse(localStorage.getItem('user'));

    // If no user data, redirect to login page
    if (!user || user.role !== 'admin') {
      navigate('/');
    }
    fetchAndSetDepartmentHeads();

    // Polling every 5 seconds
    const intervalId = setInterval(() => {
      fetchAndSetDepartmentHeads();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const addDepartmentHead = async () => {
    // Validate email domain (must end with @evsu.edu.ph)
    if (!newHead.email.endsWith('@evsu.edu.ph')) {
      setEmailError('Email must end with @evsu.edu.ph');
      return;
    } else {
      setEmailError('');
    }
  
    // Validate phone number (must be exactly 11 digits)
    if (!/^\d{11}$/.test(newHead.phone)) {
      setPhoneError('Phone number must be exactly 11 digits.');
      return; // Stop execution if the validation fails
    } else {
      setPhoneError(''); // Clear the error message if validation passes
    }
  
    // Validate password (at least 8 characters, 1 uppercase, and 1 symbol)
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;
    if (!passwordRegex.test(newHead.password)) {
      setPasswordError('Password must be at least 8 characters long, contain 1 uppercase letter, and 1 symbol.');
      return;
    } else {
      setPasswordError('');
    }
  
    try {
      console.log('Checking if the email already exists:', newHead.email);
  
      // Check if the email exists in the 'department_head' table
      const { data: existingDeptHeadData, error: deptHeadEmailCheckError } = await supabase
        .from('department_head')
        .select('email')
        .eq('email', newHead.email);
  
      if (deptHeadEmailCheckError) {
        console.error('Error checking department head email existence:', deptHeadEmailCheckError);
        console.error('Full error:', JSON.stringify(deptHeadEmailCheckError, null, 2)); // Full error logging
        return;
      }
  
      if (existingDeptHeadData.length > 0) {
        // If the email exists in department_head
        alert('This email is already in use by another department head. Please use a different email address.');
        return;
      }
  
      // Check if the email exists in the 'employee' table
      const { data: existingEmployeeData, error: employeeEmailCheckError } = await supabase
        .from('employee')
        .select('email')
        .eq('email', newHead.email);
  
      if (employeeEmailCheckError) {
        console.error('Error checking employee email existence:', employeeEmailCheckError);
        console.error('Full error:', JSON.stringify(employeeEmailCheckError, null, 2)); // Full error logging
        return;
      }
  
      if (existingEmployeeData.length > 0) {
        // If the email exists in employee
        alert('This email is already registered as an employee. Please use a different email address.');
        return;
      }
  
      // If the email is unique in both tables, insert the new department head into the database
      console.log('Adding new department head:', newHead);
      const { data, error } = await supabase.from('department_head').insert([newHead]);
  
      if (error) {
        console.error('Error adding department head:', error);
        alert(`Failed to insert department head: ${error.message}`);
        return;
      }
  
      console.log('New Head Object:', newHead);
      console.log('Added department head data:', data);
  
      if (Array.isArray(data)) {
        // Fetch and update the department heads list after adding
        await fetchAndSetDepartmentHeads();
      } else {
        console.error('Unexpected data format:', data);
      }
    } catch (error) {
      console.error('Error adding department head:', error);
    } finally {
      setShowModal(false);
      setNewHead({
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        department: '',
        phone: '',
      });
    }
  };

  
  const deleteDepartmentHead = async () => {
    if (!selectedHead) return;

    try {
      console.log('Deleting department head:', selectedHead);
      const { error } = await supabase.from('department_head').delete().eq('id', selectedHead.id);
      if (error) {
        console.error('Error deleting department head:', error);
        return;
      }
      // Refresh the department heads list after deletion
      await fetchAndSetDepartmentHeads();
    } catch (error) {
      console.error('Error deleting department head:', error);
    } finally {
      setShowDeleteModal(false);
      setSelectedHead(null);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(`Input changed: ${name} = ${value}`);
    setNewHead(prev => ({ ...prev, [name]: value }));
  };

  const handlePhoneInput = (e) => {
    const value = e.target.value;
    // Only keep numeric values
    const numericValue = value.replace(/\D/g, '');
    setNewHead(prev => ({ ...prev, phone: numericValue }));
  };

  const handleViewInfo = (head) => {
    console.log('Viewing info for:', head);
    navigate(`/ViewDepartmentHeadInfo?id=${head.id}`); // Navigate to DepartmentHeadViewInfo with ID
  };

  return (
    <div
      className="dashboard-container"
      style={{
        display: 'flex',
        backgroundColor: isDarkMode ? '#333' : 'lightgrey',
        color: isDarkMode ? '#f0f0f0' : '#000', // Text color based on mode
        minHeight: '100vh',
      }}
    >
      <Sidebar /> {/* Sidebar Component */}

      <div style={{ flex: 1 }}>
        <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} /> {/* Header Component */}

        <main
          className="main-content"
          style={{
            backgroundColor: isDarkMode ? '#444' : 'white',
            padding: '20px',
            borderRadius: '8px',
            margin: '20px',
            color: isDarkMode ? '#f0f0f0' : '#000', // Text color for main content
          }}
        >
          <h1>Manage Department Heads/Head Office</h1>
          <br />
          <div>
            <Button variant="primary" onClick={() => setShowModal(true)}>
              Add New Department Head/Head Office
            </Button>
          </div>
          <br />

          <div className="table-responsive">
            <Table striped bordered hover variant={isDarkMode ? 'dark' : 'light'}>
              <thead>
                <tr className="text-center"> {/* Center align table headers */}

                  <th>Email</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Department</th>
                  <th>Phone</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {departmentHeads.length === 0 ? (
                  <tr>
                    <td colSpan="7" className="text-center">No department heads/Head Office found</td> {/* Adjust colspan */}
                  </tr>
                ) : (
                  departmentHeads.map((head) => (
                    <tr key={head.id} className="text-center"> {/* Center align table cells */}

                      <td>{head.email}</td>
                      <td>{head.firstname}</td>
                      <td>{head.lastname}</td>
                      <td>{head.department}</td>
                      <td>{head.phone}</td>
                      <td>
                        <Button
                          variant="success"
                          size="sm"
                          className="me-2"
                          onClick={() => handleViewInfo(head)}
                        >
                          View Info
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => {
                            setSelectedHead(head);
                            setShowDeleteModal(true);
                          }}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>

          {/* Modal for adding new department head */}
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Add New Department Head/Head Office</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={newHead.email}
                    placeholder="Enter email (it should be @evsu.edu.ph)"
                    onChange={handleInputChange}
                    required
                  />
                  {emailError && <div style={{ color: 'red', fontSize: '12px' }}>{emailError}</div>}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={newHead.password}
                    placeholder="Should be 8 Characters at least 1 Uppercase and symbol"
                    onChange={handleInputChange}
                    required
                  />
                  {passwordError && <div style={{ color: 'red', fontSize: '12px' }}>{passwordError}</div>}
                </Form.Group>

                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstname"
                    value={newHead.firstname}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    value={newHead.lastname}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Department/Head Office</Form.Label>
                  <Form.Select
                    name="department"
                    value={newHead.department}
                    onChange={handleInputChange}
                    required
                  >
                      <option value="">Select Department</option> {/* Default empty option */}
                      <option value="Accounting Office">Accounting Office</option>
                      <option value="Administrative and Finance Services">Administrative and Finance Services</option>
                      <option value="Budget Office">Budget Office</option>
                      <option value="Business and Management Department">Business and Management Department</option>
                      <option value="Campus Clinic">Campus Clinic</option>
                      <option value="Cashier and Disbursing Office">Cashier and Disbursing Office</option>
                      <option value="Computer Studies Department">Computer Studies Department</option>
                      <option value="Culture and the Arts">Culture and the Arts</option>
                      <option value="Engineering Department">Engineering Department</option>
                      <option value="Gender and Development Office">Gender and Development Office</option>
                      <option value="Human Resource Management Office">Human Resource Management Office</option>
                      <option value="Institutional Planning and Development Office">Institutional Planning and Development Office</option>
                      <option value="Library">Library</option>
                      <option value="Maintenance and Engineering">Maintenance and Engineering</option>
                      <option value="NSTP Office">NSTP Office</option>
                      <option value="Office of the Campus Director">Office of the Campus Director</option>
                      <option value="Property Inspector">Property Inspector</option>
                      <option value="Quality Assurance Accreditation Center">Quality Assurance Accreditation Center</option>
                      <option value="Registrar Office">Registrar Office</option>
                      <option value="Teacher Education Department">Teacher Education Department</option>
                      <option value="Technology Department">Technology Department</option>
                      <option value="Guidance Office">Guidance Office</option>
                      <option value="Income-Generating Projects">Income-Generating Projects </option>
                      <option value="Student Affairs and Services Office">Student Affairs and Services Office</option>
                      <option value="Procurement Office">Procurement Office</option>
                      <option value="Sports and Development Office">Sports and Development Office</option>
                      <option value="Research and Extension Office">Research and Extension Office</option>
                  </Form.Select>
                </Form.Group>


                <Form.Group>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={newHead.phone}
                    onChange={handlePhoneInput}
                    required
                  />
                  {phoneError && <div style={{ color: 'red', fontSize: '12px' }}>{phoneError}</div>}
                </Form.Group>

              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={addDepartmentHead}>
                Add Department Head/Head Office
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Modal for deleting department head */}
          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Department Head/Head Office</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this Department head/Head Office?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Close
              </Button>
              <Button variant="danger" onClick={deleteDepartmentHead}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </main>
      </div>
    </div>
  );
};

export default ManageDH;